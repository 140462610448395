body:not(.not-ie){
	.text-shadow{
		filter: none;
		background: none;
		&:before,
		&:after{
			filter: none;
			background: none;
		}
	}
	.organizer-item__name{
		min-height: 1px;
	}
	.prize__value{
		color: $main-color-light;
	}
	.registration-steps{
		li{
			&:before{
				color: $main-color-light;
			}
		}
	}
}