@import "includes";

.tickets-tabset__item + .tickets-tabset__item {
  margin-left: -0.3rem;
}

@media (max-width: 767px) {
  .tickets-tabset__item + .tickets-tabset__item {
    margin-left: 0;
  }
  .tickets-tabset__item + .tickets-tabset__item {
    margin-top: -0.3rem;
  }
}