.s-404{
	@include clearfix;
	padding: 15rem 0 5rem;
	.s-title{
		margin-bottom: 3rem;
	}
	p{
		font-size: em(18);
	}
}
@media screen and (max-width:$point-3) {
	.s-404{
		p{
			font-size: em(16);
		}
	}
}