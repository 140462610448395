.swiper-container {
	width: 100%;
	height: 100%;
	margin-left: auto;
	margin-right: auto;
}
.swiper-slide {
	text-align: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}
.swiper-container-vertical{
	& > .swiper-pagination-bullets{
		@include transform(translateX(0) translateY(0));
		@include flex_block(row, wrap, center, center, center);
		left: 50%;
		margin-left: -.5rem;
		width: 1rem;
		top: 4rem;
		bottom: 0;
		overflow: hidden;
		.swiper-pagination-bullet{
			@include transition;
			width: 1rem;
			height: 1rem;
			opacity: 1;
			margin: 1rem 0;
			position: relative;
			background-color: transparent;
			&:before{
				@include transition;
				@include border-radius(.75rem);
				content: '';
				right: 0;
				width: calc(100% - .4rem);
				height: calc(100% - .4rem);
				background-color: transparent;
				border: .2rem solid #fff;
				position: absolute;
			}
			&:after{
				content:'';
				position: absolute;
				height: 999px;
				width: .1rem;
				background-color: #fff;
				opacity: 0;
				visibility: hidden;
			}
			&:first-child{
				&:after{
					opacity:.4;
					visibility: visible;
					bottom: calc(100% + 3rem);
				}
			}
			&:last-child{
				&:after{
					opacity:.4;
					visibility: visible;
					top: calc(100% + 3rem);
				}
			}
		}
		.swiper-pagination-bullet-active{
			&:before{
				background-color: #fff;
				bottom: -.5rem;
				height: calc(100% + 1rem);
			}
		}
	}
}
