@keyframes logoShadowChange {
	0% {
		opacity: 1;
	}
	50%{
		opacity: 0;
	}
	100%{
		opacity: 1;
	}
}

@keyframes logoHoverShadowChange {
	0% {
		opacity: 0;
	}
	50%{
		opacity: 1;
	}
	100%{
		opacity: 0;
	}
}

@keyframes fadeInDownHeader {
	0% {
		-webkit-transform: translate3d(0, -50px, 0);
		transform: translate3d(0, -50px, 0);
		visibility: visible;
		opacity:0;
	}

	100% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		opacity:1;
	}
}

@keyframes fadeInUp {
	0% {
		-webkit-transform: translate3d(0, 50px, 0);
		transform: translate3d(0, 50px, 0);
		visibility: visible;
		opacity:0;
	}

	75% {
		-webkit-transform: translate3d(0, 50px, 0);
		transform: translate3d(0, 50px, 0);
		visibility: visible;
		opacity:0;
	}

	100% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		opacity:1;
	}
}

@keyframes introContactAnimation {
	0% {
		-webkit-transform: translateX(-50px) translateY(-50%);
		transform: translateX(-50px) translateY(-50%);
		visibility: visible;
		opacity:0;
	}

	50% {
		-webkit-transform: translateX(-50px) translateY(-50%);
		transform:translateX(-50px) translateY(-50%);
		visibility: visible;
		opacity:0;
	}

	100% {
		-webkit-transform: translateX(0) translateY(-50%);
		transform: translateX(0) translateY(-50%);
		opacity:1;
	}
}

@keyframes introStaticContactAnimation {
	0% {
		-webkit-transform: translateX(-50px) translateY(0);
		transform: translateX(-50px) translateY(0);
		visibility: visible;
		opacity:0;
	}

	50% {
		-webkit-transform: translateX(-50px) translateY(0);
		transform:translateX(-50px) translateY(0);
		visibility: visible;
		opacity:0;
	}

	100% {
		-webkit-transform: translateX(0) translateY(0);
		transform: translateX(0) translateY(0);
		opacity:1;
	}
}

@keyframes introGalleryAnimation {
	0% {
		-webkit-transform: translate3d(50px, 0, 0);
		transform: translate3d(-50px, 0, 0);
		visibility: visible;
		opacity:0;
	}

	50% {
		-webkit-transform: translate3d(50px, 0, 0);
		transform: translate3d(50px, 0, 0);
		visibility: visible;
		opacity:0;
	}

	100% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		opacity:1;
	}
}

@keyframes paginationAnimation {
	0% {
		-webkit-transform: translate3d(50px, 0, 0);
		transform: translate3d(-50px, 0, 0);
		visibility: visible;
		opacity:0;
	}

	50% {
		-webkit-transform: translate3d(50px, 0, 0);
		transform: translate3d(50px, 0, 0);
		visibility: visible;
		opacity:0;
	}

	100% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		opacity:1;
	}
}

@keyframes fadeInRightContact {
	0% {
		-webkit-transform: translate3d(50px, 0, 0);
		transform: translate3d(50px, 0, 0);
		visibility: visible;
		opacity:0;
	}

	80% {
		-webkit-transform: translate3d(50px, 0, 0);
		transform: translate3d(50px, 0, 0);
		visibility: visible;
		opacity:0;
	}

	100% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		opacity:1;
	}
}


// mobile animation
@keyframes slideInLeftContactXS {
	0% {
		-webkit-transform: translate3d(-50px, 0, 0);
		transform: translate3d(-50px, 0, 0);
		visibility: visible;
		opacity:0;
	}

	75% {
		-webkit-transform: translate3d(-50px, 0, 0);
		transform: translate3d(-50px, 0, 0);
		visibility: visible;
		opacity:0;
	}

	100% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		opacity:1;
	}
}

@keyframes loaderAnimationImgHover {
	0% {
		width: 1px;
		visibility: visible;
		opacity:0;
	}
	25% {
		width: 1px;
		visibility: visible;
		opacity:0;
	}
	100%{
		width: 200px;
		visibility: hidden;
		opacity:1;
	}
}

@media screen and (max-width:$point-3) {
	@keyframes introContactAnimation {
		0% {
			-webkit-transform: translateX(-50px) translateY(0);
			transform: translateX(-50px) translateY(0);
			visibility: visible;
			opacity:0;
		}

		50% {
			-webkit-transform: translateX(-50px) translateY(0);
			transform:translateX(-50px) translateY(0);
			visibility: visible;
			opacity:0;
		}

		100% {
			-webkit-transform: translateX(0) translateY(0);
			transform: translateX(0) translateY(0);
			opacity:1;
		}
	}
	@keyframes introGalleryAnimation {
		0% {
			-webkit-transform: translate3d(0, 50px, 0);
			transform: translate3d(0, 50px, 0);
			visibility: visible;
			opacity:0;
		}

		75% {
			-webkit-transform: translate3d(0, 50px, 0);
			transform: translate3d(0, 50px, 0);
			visibility: visible;
			opacity:0;
		}

		100% {
			-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
			opacity:1;
		}
	}
}