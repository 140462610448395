h1,
.style-h1{
	font-size: em(60);
	line-height: em(55,60);
	color: $main-color-light;
	font-style: italic;
	font-weight: 800;
	letter-spacing: -.2rem;
	margin-bottom: 4rem;
	&._lg{
		font-size: em(80);
		line-height: em(80,80);
	}
}
h2{
	font-size: em(64);
	line-height: em(58,64);
	font-weight: 800;
	font-style: italic;
	margin-bottom: 4.8rem;
	text-transform: none;
	letter-spacing: -.2rem;
	&.s-intro__h2{
		color: $main-color-light;
		font-size: em(30);
		letter-spacing: -.1rem;
		width: 100%;
	}
}
h3{
	font-size: em(36);
	font-weight: 900;
	margin-bottom: 3rem;
}
h4{
	font-size: em(20);
	line-height: em(26,20);
	font-weight: 900;
	margin-bottom: 1.1rem;
}

h5{
	font-size: 1rem;
	font-weight: 600;
	text-transform: uppercase;
}
@media screen and (max-width:$point-2) {
	h1,
	.style-h1{
		font-size: em(50);
		letter-spacing: -.1rem;
		&._lg{
			font-size: em(42);
		}
	}
	h2{
		font-size: em(50);
		letter-spacing: -.1rem;
		margin-bottom: 4rem;
	}
}
@media screen and (max-width:$point-3) {
	h2{
		font-size: em(42);
		line-height: em(48,42);
		margin-bottom: 4.1rem;
	}
	h3{
		font-size: em(20);
		line-height: em(25,20);
	}

}