.btn {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  font: 800 1em "helveticaneuecyr", sans-serif;
  text-align: center;
  color: #fff;
  height: 6.4rem;
  line-height: 6.4rem;
  border: 0;
  max-width: 100%;
  -webkit-backface-visibility: hidden;
  z-index: 1;
  position: relative;
  padding: 0 2rem;
  text-decoration: none; }
  .btn:after {
    -moz-transition: all 0.4s ease-out;
    -webkit-transition: all 0.4s ease-out;
    -o-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0; }
  .btn:hover, .btn:focus {
    color: #fff; }
    .btn:hover:after, .btn:focus:after {
      -moz-transition: all 0.4s ease-in;
      -webkit-transition: all 0.4s ease-in;
      -o-transition: all 0.4s ease-in;
      transition: all 0.4s ease-in;
      opacity: 1; }
  .btn._default {
    background-image: linear-gradient(to left, #ef5f41, #d02703); }
    .btn._default:after {
      background: #fff; }
    .btn._default:hover, .btn._default:focus {
      color: #d02703; }
  .btn._primary {
    -webkit-box-shadow: 0 10px 40px 0 #ef5f41;
    box-shadow: 0 10px 40px 0 #ef5f41;
    background-color: #d02703;
    color: #fff; }
    .btn._primary:hover, .btn._primary:focus {
      background-color: #ef5f41;
      color: #363636; }
  .btn._wide {
    min-width: 40.3rem;
    padding: 0 3rem; }
  .btn._success {
    background-color: #41e163;
    color: #363636; }
  .btn._danger {
    background-color: #df3733; }

.link-holder {
  position: relative;
  z-index: 2; }

@media screen and (max-width: 767px) {
  .btn._wide {
    width: 100%;
    min-width: 1px;
    max-width: 48rem;
    display: block;
    margin-right: auto;
    margin-left: auto;
    padding: 0 2rem; } }

@font-face {
  font-family: "helveticaneuecyr";
  src: url("../fonts/helveticaneuecyr-black.eot");
  src: url("../fonts/helveticaneuecyr-black.eot?#iefix") format("embedded-opentype"), url("../fonts/helveticaneuecyr-black.woff2") format("woff2"), url("../fonts/helveticaneuecyr-black.ttf") format("truetype"), url("../fonts/helveticaneuecyr-black.svg#helveticaneuecyr") format("svg");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: "helveticaneuecyr";
  src: url("../fonts/helveticaneuecyr-blackItalic.eot");
  src: url("../fonts/helveticaneuecyr-blackItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/helveticaneuecyr-blackItalic.woff2") format("woff2"), url("../fonts/helveticaneuecyr-blackItalic.ttf") format("truetype"), url("../fonts/helveticaneuecyr-blackItalic.svg#helveticaneuecyr") format("svg");
  font-weight: 900;
  font-style: italic; }

@font-face {
  font-family: "helveticaneuecyr";
  src: url("../fonts/helveticaneuecyr-heavy.eot");
  src: url("../fonts/helveticaneuecyr-heavy.eot?#iefix") format("embedded-opentype"), url("../fonts/helveticaneuecyr-heavy.woff2") format("woff2"), url("../fonts/helveticaneuecyr-heavy.ttf") format("truetype"), url("../fonts/helveticaneuecyr-heavy.svg#helveticaneuecyr") format("svg");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: "helveticaneuecyr";
  src: url("../fonts/helveticaneuecyr-heavyItalic.eot");
  src: url("../fonts/helveticaneuecyr-heavyItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/helveticaneuecyr-heavyItalic.woff2") format("woff2"), url("../fonts/helveticaneuecyr-heavyItalic.ttf") format("truetype"), url("../fonts/helveticaneuecyr-heavyItalic.svg#helveticaneuecyr") format("svg");
  font-weight: 800;
  font-style: italic; }

@font-face {
  font-family: "helveticaneuecyr";
  src: url("../fonts/helveticaneuecyr-bold.eot");
  src: url("../fonts/helveticaneuecyr-bold.eot?#iefix") format("embedded-opentype"), url("../fonts/helveticaneuecyr-bold.woff2") format("woff2"), url("../fonts/helveticaneuecyr-bold.ttf") format("truetype"), url("../fonts/helveticaneuecyr-bold.svg#helveticaneuecyr") format("svg");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: "helveticaneuecyr";
  src: url("../fonts/helveticaneuecyr.eot");
  src: url("../fonts/helveticaneuecyr.eot?#iefix") format("embedded-opentype"), url("../fonts/helveticaneuecyr.woff2") format("woff2"), url("../fonts/helveticaneuecyr.ttf") format("truetype"), url("../fonts/helveticaneuecyr.svg#helveticaneuecyr") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "helveticaneuecyr";
  src: url("../fonts/helveticaneuecyr-light.eot");
  src: url("../fonts/helveticaneuecyr-light.eot?#iefix") format("embedded-opentype"), url("../fonts/helveticaneuecyr-light.woff2") format("woff2"), url("../fonts/helveticaneuecyr-light.ttf") format("truetype"), url("../fonts/helveticaneuecyr-light.svg#helveticaneuecyr") format("svg");
  font-weight: 300;
  font-style: normal; }

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  max-height: 1000000px; }

html {
  height: 100%;
  font-size: 62.5%; }

body {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: none;
  margin: 0;
  font-family: "helveticaneuecyr", sans-serif;
  font-size: 2em;
  line-height: 1.45;
  color: #363636;
  -webkit-font-smoothing: antialiased;
  min-height: 100%;
  font-weight: 400;
  min-width: 32rem;
  width: 100%;
  position: relative; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section,
summary {
  display: block; }

img {
  border-style: none;
  vertical-align: top;
  max-width: 100%;
  height: auto; }

button {
  -moz-transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out; }

a {
  -moz-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  color: #ef5f41; }
  a:hover {
    color: #ef5f41; }

strong {
  font-weight: 600; }

input,
textarea,
select {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  vertical-align: middle; }

form,
fieldset {
  margin: 0;
  padding: 0;
  border-style: none; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button; }
  button:active, button:focus,
  html input[type="button"]:active,
  html input[type="button"]:focus,
  input[type="reset"]:active,
  input[type="reset"]:focus,
  input[type="submit"]:active,
  input[type="submit"]:focus {
    outline: none !important; }

input[type="text"]:focus,
input[type="tel"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
textarea:focus {
  outline: none; }
  input[type="text"]:focus::-webkit-input-placeholder,
  input[type="tel"]:focus::-webkit-input-placeholder,
  input[type="email"]:focus::-webkit-input-placeholder,
  input[type="url"]:focus::-webkit-input-placeholder,
  input[type="password"]:focus::-webkit-input-placeholder,
  input[type="search"]:focus::-webkit-input-placeholder,
  textarea:focus::-webkit-input-placeholder {
    color: transparent; }
  input[type="text"]:focus::-moz-placeholder,
  input[type="tel"]:focus::-moz-placeholder,
  input[type="email"]:focus::-moz-placeholder,
  input[type="url"]:focus::-moz-placeholder,
  input[type="password"]:focus::-moz-placeholder,
  input[type="search"]:focus::-moz-placeholder,
  textarea:focus::-moz-placeholder {
    color: transparent; }
  input[type="text"]:focus:-moz-placeholder,
  input[type="tel"]:focus:-moz-placeholder,
  input[type="email"]:focus:-moz-placeholder,
  input[type="url"]:focus:-moz-placeholder,
  input[type="password"]:focus:-moz-placeholder,
  input[type="search"]:focus:-moz-placeholder,
  textarea:focus:-moz-placeholder {
    color: transparent; }
  input[type="text"]:focus:-ms-input-placeholder,
  input[type="tel"]:focus:-ms-input-placeholder,
  input[type="email"]:focus:-ms-input-placeholder,
  input[type="url"]:focus:-ms-input-placeholder,
  input[type="password"]:focus:-ms-input-placeholder,
  input[type="search"]:focus:-ms-input-placeholder,
  textarea:focus:-ms-input-placeholder {
    color: transparent; }

input[type="text"]::-webkit-input-placeholder,
input[type="tel"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder,
input[type="url"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
input[type="search"]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: transparent;
  opacity: 0; }

input[type="text"]::-moz-placeholder,
input[type="tel"]::-moz-placeholder,
input[type="email"]::-moz-placeholder,
input[type="url"]::-moz-placeholder,
input[type="password"]::-moz-placeholder,
input[type="search"]::-moz-placeholder,
textarea::-moz-placeholder {
  color: transparent;
  opacity: 0; }

input[type="text"]:-moz-placeholder,
input[type="tel"]:-moz-placeholder,
input[type="email"]:-moz-placeholder,
input[type="url"]:-moz-placeholder,
input[type="password"]:-moz-placeholder,
input[type="search"]:-moz-placeholder,
textarea:-moz-placeholder {
  color: transparent;
  opacity: 0; }

input[type="text"]:-ms-input-placeholder,
input[type="tel"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder,
input[type="url"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
input[type="search"]:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: transparent; }

input[type=text],
input[type=password],
input[type=file],
input[type=submit],
input[type=search],
input[type=email],
input[type="tel"],
input[type="url"],
textarea {
  -webkit-border-radius: none;
  -moz-border-radius: none;
  border-radius: none;
  -webkit-appearance: none; }

textarea {
  overflow: auto;
  resize: vertical;
  vertical-align: top; }

input[type="checkbox"],
input[type="radio"] {
  padding: 0; }

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  webkit-appearance: none; }

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2em;
  margin: 0 0 1.2em; }

p {
  margin: 0 0 1.6rem; }

q {
  quotes: none; }
  q:before, q:after {
    content: '';
    content: none; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

body:not(.not-ie) .text-shadow {
  filter: none;
  background: none; }
  body:not(.not-ie) .text-shadow:before, body:not(.not-ie) .text-shadow:after {
    filter: none;
    background: none; }

body:not(.not-ie) .organizer-item__name {
  min-height: 1px; }

body:not(.not-ie) .prize__value {
  color: #ef5f41; }

body:not(.not-ie) .registration-steps li:before {
  color: #ef5f41; }

.not-ie,
.not-ie * {
  cursor: url(../i/cursors/cursor-default.cur), auto; }

@media screen and (max-width: 1023px) {
  .not-ie * {
    cursor: auto; } }

@keyframes logoShadowChange {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes logoHoverShadowChange {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeInDownHeader {
  0% {
    -webkit-transform: translate3d(0, -50px, 0);
    transform: translate3d(0, -50px, 0);
    visibility: visible;
    opacity: 0; }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes fadeInUp {
  0% {
    -webkit-transform: translate3d(0, 50px, 0);
    transform: translate3d(0, 50px, 0);
    visibility: visible;
    opacity: 0; }
  75% {
    -webkit-transform: translate3d(0, 50px, 0);
    transform: translate3d(0, 50px, 0);
    visibility: visible;
    opacity: 0; }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes introContactAnimation {
  0% {
    -webkit-transform: translateX(-50px) translateY(-50%);
    transform: translateX(-50px) translateY(-50%);
    visibility: visible;
    opacity: 0; }
  50% {
    -webkit-transform: translateX(-50px) translateY(-50%);
    transform: translateX(-50px) translateY(-50%);
    visibility: visible;
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0) translateY(-50%);
    transform: translateX(0) translateY(-50%);
    opacity: 1; } }

@keyframes introStaticContactAnimation {
  0% {
    -webkit-transform: translateX(-50px) translateY(0);
    transform: translateX(-50px) translateY(0);
    visibility: visible;
    opacity: 0; }
  50% {
    -webkit-transform: translateX(-50px) translateY(0);
    transform: translateX(-50px) translateY(0);
    visibility: visible;
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
    opacity: 1; } }

@keyframes introGalleryAnimation {
  0% {
    -webkit-transform: translate3d(50px, 0, 0);
    transform: translate3d(-50px, 0, 0);
    visibility: visible;
    opacity: 0; }
  50% {
    -webkit-transform: translate3d(50px, 0, 0);
    transform: translate3d(50px, 0, 0);
    visibility: visible;
    opacity: 0; }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes paginationAnimation {
  0% {
    -webkit-transform: translate3d(50px, 0, 0);
    transform: translate3d(-50px, 0, 0);
    visibility: visible;
    opacity: 0; }
  50% {
    -webkit-transform: translate3d(50px, 0, 0);
    transform: translate3d(50px, 0, 0);
    visibility: visible;
    opacity: 0; }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes fadeInRightContact {
  0% {
    -webkit-transform: translate3d(50px, 0, 0);
    transform: translate3d(50px, 0, 0);
    visibility: visible;
    opacity: 0; }
  80% {
    -webkit-transform: translate3d(50px, 0, 0);
    transform: translate3d(50px, 0, 0);
    visibility: visible;
    opacity: 0; }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes slideInLeftContactXS {
  0% {
    -webkit-transform: translate3d(-50px, 0, 0);
    transform: translate3d(-50px, 0, 0);
    visibility: visible;
    opacity: 0; }
  75% {
    -webkit-transform: translate3d(-50px, 0, 0);
    transform: translate3d(-50px, 0, 0);
    visibility: visible;
    opacity: 0; }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes loaderAnimationImgHover {
  0% {
    width: 1px;
    visibility: visible;
    opacity: 0; }
  25% {
    width: 1px;
    visibility: visible;
    opacity: 0; }
  100% {
    width: 200px;
    visibility: hidden;
    opacity: 1; } }

@media screen and (max-width: 767px) {
  @keyframes introContactAnimation {
    0% {
      -webkit-transform: translateX(-50px) translateY(0);
      transform: translateX(-50px) translateY(0);
      visibility: visible;
      opacity: 0; }
    50% {
      -webkit-transform: translateX(-50px) translateY(0);
      transform: translateX(-50px) translateY(0);
      visibility: visible;
      opacity: 0; }
    100% {
      -webkit-transform: translateX(0) translateY(0);
      transform: translateX(0) translateY(0);
      opacity: 1; } }
  @keyframes introGalleryAnimation {
    0% {
      -webkit-transform: translate3d(0, 50px, 0);
      transform: translate3d(0, 50px, 0);
      visibility: visible;
      opacity: 0; }
    75% {
      -webkit-transform: translate3d(0, 50px, 0);
      transform: translate3d(0, 50px, 0);
      visibility: visible;
      opacity: 0; }
    100% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      opacity: 1; } } }

.icon {
  display: inline-block;
  vertical-align: top;
  width: 1em;
  height: 1em;
  fill: currentColor; }

.icon-arrow-left {
  width: 2.1em;
  height: 2.15em; }

.icon-arrow-right {
  width: 2.1em;
  height: 2.15em; }

.icon-download {
  width: 1.8em;
  height: 2.9em; }

.icon-facebook {
  width: 0.45em;
  height: 0.9em; }

.icon-home {
  width: 0.85em;
  height: 0.7em; }

.icon-instagram {
  width: 0.85em;
  height: 0.85em; }

.icon-logo {
  width: 19.508em;
  height: 11.776em; }

.icon-logo-4system {
  width: 2.8em;
  height: 3em; }

.icon-logo-el {
  width: 3.6795em;
  height: 1.927em; }

.icon-logo-feather {
  width: 8.4665em;
  height: 2.2365em; }

.icon-logo-kireev {
  width: 2.55em;
  height: 0.75em; }

.icon-logo-lock-stock-barrel {
  width: 3em;
  height: 3em; }

.icon-logo-reuzel {
  width: 2.75em;
  height: 2.99em; }

.icon-logo-simushka {
  width: 7em;
  height: 0.75em; }

.icon-moscow-city {
  width: 19.5em;
  height: 4.8em; }

.icon-mouse-next {
  width: 0.9em;
  height: 2.45em; }

.icon-print-house-logo {
  width: 5em;
  height: 4.45em; }

.icon-rbw-logo {
  width: 19.508em;
  height: 11.776em; }

.icon-triangle-rounded {
  width: 1.915em;
  height: 3.01em; }

.icon-twitter {
  width: 0.9em;
  height: 0.75em; }

.icon-vk {
  width: 1.05em;
  height: 0.6em; }

.icon-youtube {
  width: 0.85em;
  height: 0.6em; }

.content {
  min-height: calc(100vh - 454px); }

section {
  padding: 8.5rem 0; }
  section:not(.s-intro) {
    position: relative;
    z-index: 1; }
  section:focus {
    outline: none; }

.bg-white {
  padding-bottom: 8.5rem !important; }
  .bg-white.pb-0 {
    padding-bottom: 0 !important; }
  .bg-white + .bg-white {
    padding-top: 0 !important; }

img[object-fit=cover] {
  margin-bottom: 30px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; }

.custom-object-fit {
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover; }
  .custom-object-fit img {
    display: none; }

.w-100 {
  width: 100%; }

.m-0 {
  margin: 0 !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-lg {
  margin-bottom: 6.4rem; }

.mb-sm {
  margin-bottom: 2rem; }

.mb-md {
  margin-bottom: 4rem !important; }

.ml-0 {
  margin-left: 0 !important; }

.pb-md {
  padding-bottom: 4rem; }

.pt-md {
  padding-top: 4rem; }

.pt-sm {
  padding-top: 2rem; }

.pl-lg {
  padding-left: 9rem; }

.text-md {
  font-size: 1.3em;
  line-height: 1.15385em; }

.text-sm {
  font-size: 1.6rem;
  line-height: 1.5em; }

.text-half-sm {
  font-size: 1.4rem; }

.text-xs {
  font-size: 1.2rem; }

.c-link {
  color: #ef5f41; }

.c-danger {
  color: #df3733; }

.c-link-hover {
  color: #ef5f41; }

.c-base {
  color: #363636; }

.c-main-dark {
  color: #d02703; }

.c-white,
a.link-white {
  color: #fff; }

a.link-white {
  border-color: #fff; }
  a.link-white:hover {
    color: #ef5f41; }

a.link-base {
  color: #363636; }
  a.link-base:hover {
    color: #d02703; }

a.link-main-light {
  color: #ef5f41; }
  a.link-main-light:hover {
    color: #ef5f41; }

a.link-main-dark {
  color: #d02703; }
  a.link-main-dark:hover {
    color: #ef5f41; }

.text-shadow {
  position: relative; }
  .text-shadow:before, .text-shadow:after {
    -moz-transition: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    content: attr(data-text) ;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    -webkit-filter: blur(20px);
    filter: blur(20px);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(to left, #ef5f41, #d02703); }
  .text-shadow span {
    color: #fff;
    position: relative;
    z-index: 3; }

.font-weight-light {
  font-weight: 300; }

.font-weight-heavy {
  font-weight: 800; }

.font-weight-black {
  font-weight: 900; }

.font-style-italic {
  font-style: italic; }

.flex-center {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center; }

.text-center {
  text-align: center; }

.hidden {
  display: none; }

.tablet-visible {
  display: none !important; }

address {
  font-style: normal; }

.d-ib {
  display: inline-block;
  vertical-align: top; }

.d-b {
  display: block; }

.justify-space-between {
  justify-content: space-between !important; }

.h-a {
  height: auto !important;
  min-height: 1px !important; }

.content-list {
  margin: 0;
  padding: 0;
  list-style: none;
  margin: 0 0 1.6rem; }
  .content-list li {
    position: relative;
    padding: 0 0 1.2rem 2.7rem; }
    .content-list li:before {
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      content: '';
      position: absolute;
      top: .5rem;
      left: .2rem;
      background-image: linear-gradient(to bottom, #d02703, #ef5f41);
      width: 1.3rem;
      height: 1.3rem; }
    .content-list li:last-child {
      padding-bottom: 0; }
  .content-list + p.pl-ul {
    padding-left: 2.7rem; }

.btn-wrap {
  white-space: normal;
  line-height: 1.2em; }

.td-n {
  text-decoration: none; }

.test-svg {
  position: absolute;
  z-index: 1;
  top: 15rem;
  left: 5rem; }

@media screen and (min-width: 1024px) {
  .tel-link {
    pointer-events: none;
    text-decoration: none;
    color: #fff; } }

@media screen and (max-width: 1023px) {
  .desctop-visible {
    display: none !important; }
  .tablet-visible {
    display: block !important; }
  .tablet-hidden {
    display: none !important; } }

@media screen and (min-width: 768px) {
  .mobile-visible {
    display: none !important; } }

@media screen and (min-width: 767px) {
  .text-sm-md {
    font-size: 1.3em;
    line-height: 1.15385em; }
  .text-sm-center {
    text-align: center; }
  .justify-sm-center {
    justify-content: center !important; }
  .pl-sm-lg {
    padding-left: 9rem !important; } }

@media screen and (max-width: 767px) {
  .mobile-hidden {
    display: none !important; }
  section {
    padding: 8rem 0; }
  .lead {
    line-height: 1.6em; }
  .text-sm-right {
    text-align: right; }
  .text-sm-center {
    text-align: center; }
  .bg-white {
    padding-bottom: 8rem !important; }
    .bg-white + .bg-white {
      padding-top: 0 !important; } }

.swiper-container {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto; }

.swiper-slide {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center; }

.swiper-container-vertical > .swiper-pagination-bullets {
  transform: translateX(0) translateY(0);
  -moz-transform: translateX(0) translateY(0);
  -ms-transform: translateX(0) translateY(0);
  -o-transform: translateX(0) translateY(0);
  -webkit-transform: translateX(0) translateY(0);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  left: 50%;
  margin-left: -.5rem;
  width: 1rem;
  top: 4rem;
  bottom: 0;
  overflow: hidden; }
  .swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
    -moz-transition: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    width: 1rem;
    height: 1rem;
    opacity: 1;
    margin: 1rem 0;
    position: relative;
    background-color: transparent; }
    .swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet:before {
      -moz-transition: all 0.2s ease-out;
      -webkit-transition: all 0.2s ease-out;
      -o-transition: all 0.2s ease-out;
      transition: all 0.2s ease-out;
      -webkit-border-radius: 0.75rem;
      -moz-border-radius: 0.75rem;
      border-radius: 0.75rem;
      content: '';
      right: 0;
      width: calc(100% - .4rem);
      height: calc(100% - .4rem);
      background-color: transparent;
      border: .2rem solid #fff;
      position: absolute; }
    .swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet:after {
      content: '';
      position: absolute;
      height: 999px;
      width: .1rem;
      background-color: #fff;
      opacity: 0;
      visibility: hidden; }
    .swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet:first-child:after {
      opacity: .4;
      visibility: visible;
      bottom: calc(100% + 3rem); }
    .swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet:last-child:after {
      opacity: .4;
      visibility: visible;
      top: calc(100% + 3rem); }
  .swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet-active:before {
    background-color: #fff;
    bottom: -.5rem;
    height: calc(100% + 1rem); }

h1,
.style-h1 {
  font-size: 3em;
  line-height: 0.91667em;
  color: #ef5f41;
  font-style: italic;
  font-weight: 800;
  letter-spacing: -.2rem;
  margin-bottom: 4rem; }
  h1._lg,
  .style-h1._lg {
    font-size: 4em;
    line-height: 1em; }

h2 {
  font-size: 3.2em;
  line-height: 0.90625em;
  font-weight: 800;
  font-style: italic;
  margin-bottom: 4.8rem;
  text-transform: none;
  letter-spacing: -.2rem; }
  h2.s-intro__h2 {
    color: #ef5f41;
    font-size: 1.5em;
    letter-spacing: -.1rem;
    width: 100%; }

h3 {
  font-size: 1.8em;
  font-weight: 900;
  margin-bottom: 3rem; }

h4 {
  font-size: 1em;
  line-height: 1.3em;
  font-weight: 900;
  margin-bottom: 1.1rem; }

h5 {
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase; }

@media screen and (max-width: 1023px) {
  h1,
  .style-h1 {
    font-size: 2.5em;
    letter-spacing: -.1rem; }
    h1._lg,
    .style-h1._lg {
      font-size: 2.1em; }
  h2 {
    font-size: 2.5em;
    letter-spacing: -.1rem;
    margin-bottom: 4rem; } }

@media screen and (max-width: 767px) {
  h2 {
    font-size: 2.1em;
    line-height: 1.14286em;
    margin-bottom: 4.1rem; }
  h3 {
    font-size: 1em;
    line-height: 1.25em; } }

label {
  display: inline-block;
  vertical-align: top;
  margin-bottom: 0.9rem; }
  label svg.icon,
  label span.text {
    display: inline-block;
    vertical-align: middle; }

form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  margin: 0 -1rem; }

.input-group {
  position: relative;
  margin: 0 1rem 1.8rem;
  width: calc(100% - 2rem); }
  .input-group:last-of-type {
    margin-bottom: 0; }
  .input-group._half-width {
    width: calc(50% - 2rem); }
  .input-group._lg {
    width: calc(100% - 20rem); }
  .input-group._md {
    width: calc((100% - 22rem)/2); }
  .input-group._sm {
    width: 16rem; }

.form-control {
  height: 5.8rem;
  border: 3px solid #d02703;
  display: block;
  color: #d02703;
  background: none;
  width: 100%;
  font: 0.9em "helveticaneuecyr", sans-serif;
  padding: 0 1.6rem;
  background-color: #fff; }
  .form-control.error + .label-area, .form-control:focus + .label-area {
    background-color: #fff;
    color: #d02703;
    font-size: 0.7em;
    line-height: 1.28571em;
    top: -.7rem;
    left: 1.4rem;
    padding: 0 12px 0 5px;
    pointer-events: auto; }
    .form-control.error + .label-area._grey, .form-control:focus + .label-area._grey {
      background-color: #e3e3e3;
      color: #d02703; }
      .form-control.error + .label-area._grey:before, .form-control:focus + .label-area._grey:before {
        background-color: #e3e3e3; }
    .form-control.error + .label-area:before, .form-control:focus + .label-area:before {
      content: '';
      position: absolute;
      top: .7rem;
      background-color: #fff;
      height: .3rem;
      right: 0;
      left: 0; }
    .form-control.error + .label-area label, .form-control:focus + .label-area label {
      position: relative; }
  .form-control.error {
    border-color: #d0021b; }
    .form-control.error + .label-area {
      color: #d0021b; }

.label-area {
  -moz-transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  color: rgba(208, 39, 3, 0.6);
  position: absolute;
  top: 1.9rem;
  left: 1.6rem;
  font: 0.9em "helveticaneuecyr", sans-serif;
  pointer-events: none; }
  .label-area.show {
    color: #d02703;
    font-size: 0.7em;
    line-height: 1.28571em;
    top: -.7rem;
    left: 1.4rem;
    padding: 0 12px 0 5px;
    pointer-events: auto; }
    .label-area.show._grey:before {
      background-color: #e3e3e3; }
    .label-area.show:before {
      content: '';
      position: absolute;
      top: .7rem;
      background-color: #fff;
      height: .3rem;
      right: 0;
      left: 0; }
    .label-area.show label {
      position: relative; }

.error-area {
  padding-top: 2px; }

label.error {
  color: #d0021b;
  font-size: 0.7em; }

textarea.form-control {
  padding-top: 1.7rem;
  height: 11.6rem;
  min-height: 11.6rem;
  max-height: 11.6rem;
  resize: none; }

.ticket-counter-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0 1rem 4rem; }

.ticket-counter-wrap__text {
  font-size: 0.9em;
  margin-right: 2.6rem;
  color: #d02703; }

.ticket-counter {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: center; }

.ticket-counter__minus,
.ticket-counter__plus {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  width: 4.4rem;
  height: 4.4rem;
  border: 0.3rem solid #d02703;
  background: none;
  position: relative;
  margin: 0 .8rem; }
  .ticket-counter__minus:after, .ticket-counter__minus:before,
  .ticket-counter__plus:after,
  .ticket-counter__plus:before {
    -moz-transition: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: #d02703; }
  .ticket-counter__minus:after,
  .ticket-counter__plus:after {
    width: 1.6rem;
    height: .5rem; }
  .ticket-counter__minus:before,
  .ticket-counter__plus:before {
    width: .5rem;
    height: 1.6rem; }
  .ticket-counter__minus:hover,
  .ticket-counter__plus:hover {
    background-color: #d02703; }
    .ticket-counter__minus:hover:before, .ticket-counter__minus:hover:after,
    .ticket-counter__plus:hover:before,
    .ticket-counter__plus:hover:after {
      background-color: #fff; }

.ticket-counter__minus:before {
  display: none; }

.ticket-counter__input {
  width: 14.4rem;
  padding: 0 .2rem;
  font-size: 0.9em;
  font-weight: 900;
  text-align: center;
  margin: 0 .8rem; }

.radio-group {
  padding-bottom: 1.4rem; }

/* custom radio and checkbox */
[type="radio"],
[type="checkbox"] {
  position: absolute;
  left: -9999px; }
  [type="radio"]:checked, [type="radio"]:not(:checked),
  [type="checkbox"]:checked,
  [type="checkbox"]:not(:checked) {
    position: absolute;
    left: -9999px; }
    [type="radio"]:checked + label, [type="radio"]:not(:checked) + label,
    [type="checkbox"]:checked + label,
    [type="checkbox"]:not(:checked) + label {
      position: relative;
      display: inline-block;
      font-size: 0.9em;
      color: #d02703;
      padding: .2rem 0 .6rem 4.9rem; }
      [type="radio"]:checked + label:before, [type="radio"]:not(:checked) + label:before,
      [type="checkbox"]:checked + label:before,
      [type="checkbox"]:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 2.4rem;
        height: 2.4rem;
        border: 0.5rem solid #d02703;
        background: #fff; }
      [type="radio"]:checked + label:after, [type="radio"]:not(:checked) + label:after,
      [type="checkbox"]:checked + label:after,
      [type="checkbox"]:not(:checked) + label:after {
        -moz-transition: all 0.2s ease-out;
        -webkit-transition: all 0.2s ease-out;
        -o-transition: all 0.2s ease-out;
        transition: all 0.2s ease-out;
        position: absolute; }
  [type="radio"]:not(:checked) + label:after,
  [type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0); }
  [type="radio"]:checked + label:after,
  [type="checkbox"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1); }
  [type="radio"]:focus + label:before,
  [type="checkbox"]:focus + label:before {
    border-color: #ef5f41; }

[type="radio"]:checked + label:before, [type="radio"]:not(:checked) + label:before {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%; }

[type="radio"]:checked + label:after, [type="radio"]:not(:checked) + label:after {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  content: '';
  width: 1.8rem;
  height: 1.8rem;
  background-image: linear-gradient(to bottom, #d02703, #ef5f41);
  top: .8rem;
  left: .8rem; }

[type="checkbox"]:checked + label:after, [type="checkbox"]:not(:checked) + label:after {
  width: 0.9rem;
  height: 0.9rem;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  border-right: 0.2rem solid #ef5f41;
  border-bottom: 0.2rem solid #ef5f41;
  border-top: 0;
  border-left: 0;
  height: 1.6rem;
  content: '';
  top: -.5rem;
  left: .9rem; }

@media screen and (max-width: 767px) {
  .input-group._half-width {
    width: 100%;
    margin-bottom: 1.8rem; }
  .ticket-counter-wrap {
    flex-wrap: wrap;
    padding: 1.6rem 1rem 6rem; }
  .ticket-counter-wrap__text {
    width: 100%;
    color: #d02703;
    margin: 0 0 1.6rem; }
  .ticket-counter {
    margin: 0 auto; }
  .input-group._half-width, .input-group._lg, .input-group._md, .input-group._sm {
    width: 100%; } }

.menu-opener {
  -moz-transition: all 0.4s ease-out;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  width: calc(100% - 17.8rem);
  max-width: 50%;
  height: 6.4rem;
  position: relative;
  z-index: 17;
  padding: 0 1rem;
  border: 0;
  background-image: linear-gradient(to left, #ef5f41, #d02703);
  font: 800 1em "helveticaneuecyr", sans-serif;
  color: #fff; }
  .menu-opener:after {
    -moz-transition: all 0.4s ease-out;
    -webkit-transition: all 0.4s ease-out;
    -o-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    z-index: -1;
    background: #fff;
    opacity: 0;
    -webkit-transition-delay: 700ms, 700ms;
    transition-delay: 700ms, 700ms; }
  .menu-opener:hover, .menu-opener:focus {
    border: 0; }

.menu-opener__inner {
  -moz-transition: all 0.4s ease-out;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  -webkit-transition-delay: 700ms, 700ms;
  transition-delay: 700ms, 700ms;
  pointer-events: none;
  position: relative; }
  .menu-opener__inner:before, .menu-opener__inner:after {
    -moz-transition: all 0.4s ease-out;
    -webkit-transition: all 0.4s ease-out;
    -o-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
    opacity: 0;
    visibility: hidden;
    -webkit-transition-delay: 700ms, 700ms;
    transition-delay: 700ms, 700ms;
    position: absolute;
    width: 3.3rem;
    height: .8rem;
    background-color: #ef5f41;
    content: '';
    left: calc(50% - 1.5rem);
    top: calc(50% - .4rem); }
  .menu-opener__inner:before {
    transform: rotate(45deg); }
  .menu-opener__inner:after {
    transform: rotate(-45deg); }

.menu-opener.menu-active {
  background: #fff; }
  .menu-opener.menu-active:after {
    -moz-transition: all 0.4s ease-in;
    -webkit-transition: all 0.4s ease-in;
    -o-transition: all 0.4s ease-in;
    transition: all 0.4s ease-in;
    opacity: 1;
    -moz-transition: all 0.4s ease-in;
    -webkit-transition: all 0.4s ease-in;
    -o-transition: all 0.4s ease-in;
    transition: all 0.4s ease-in;
    -webkit-transition-delay: 1ms, 1ms;
    transition-delay: 1ms, 1ms;
    opacity: 1; }
  .menu-opener.menu-active .menu-opener__inner,
  .menu-opener.menu-active .menu-opener__inner:after,
  .menu-opener.menu-active .menu-opener__inner:before {
    -moz-transition: all 0.4s ease-in;
    -webkit-transition: all 0.4s ease-in;
    -o-transition: all 0.4s ease-in;
    transition: all 0.4s ease-in;
    -webkit-transition-delay: 1ms, 1ms;
    transition-delay: 1ms, 1ms; }
  .menu-opener.menu-active .menu-opener__inner {
    transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    font-size: 0;
    line-height: 0; }
    .menu-opener.menu-active .menu-opener__inner:before, .menu-opener.menu-active .menu-opener__inner:after {
      background-color: #000;
      opacity: 1;
      visibility: visible; }

.social-networks {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative; }
  .social-networks + .scroll-down {
    padding-top: 4rem; }

.social-networks__link {
  width: 3.8rem;
  height: 3.8rem;
  display: block;
  text-align: center;
  overflow: hidden;
  border: .2rem solid #fff;
  position: relative;
  color: #fff; }
  .social-networks__link .link-holder {
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center; }
  .social-networks__link:after {
    -moz-transition: all 0.4s ease-out;
    -webkit-transition: all 0.4s ease-out;
    -o-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    background-image: linear-gradient(to left, #ef5f41, #d02703); }
  .social-networks__link:hover {
    color: #fff !important;
    border-width: 0;
    border-color: #fff; }
    .social-networks__link:hover:after {
      -moz-transition: all 0.4s ease-in;
      -webkit-transition: all 0.4s ease-in;
      -o-transition: all 0.4s ease-in;
      transition: all 0.4s ease-in;
      opacity: 1; }
  .social-networks__link._style-grey {
    border-width: .1rem;
    color: rgba(255, 255, 255, 0.7); }
    .social-networks__link._style-grey:hover {
      color: #fff;
      border-width: 0 .1rem; }
  .social-networks__link._style-black {
    border-color: #363636;
    color: #363636; }
    .social-networks__link._style-black:hover {
      color: #fff; }

.social-networks__item {
  -moz-transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  margin-left: 1rem;
  width: 3.8rem; }
  .social-networks__item:first-child {
    margin-left: 0; }
  .social-networks__item.ml-0 {
    margin-right: -.1rem;
    margin-top: -.1rem; }
  .social-networks__item:first-child .social-networks__link._style-grey:hover {
    border-left-color: #d02703; }
  .social-networks__item:last-child .social-networks__link._style-grey:hover {
    border-right-color: #ef5f41; }

.social-networks__icon {
  display: inline-block;
  vertical-align: middle; }

#loader {
  position: fixed;
  z-index: 1002;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000; }

.loader__img {
  width: 200px;
  height: 143px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -71px 0 0 -100px;
  background: url(../i/logo/logo.svg);
  -webkit-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1; }

.loader__img_hover {
  width: 200px;
  height: 143px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -71px 0 0 -100px;
  z-index: 999;
  animation-name: loaderAnimationImgHover;
  animation-duration: 3s;
  overflow: hidden; }
  .loader__img_hover:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    height: 143px;
    background: url(../i/logo/logo_color.svg);
    -webkit-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat; }

.video-item {
  overflow: hidden;
  position: relative;
  z-index: 1;
  width: 100%;
  background-image: radial-gradient(circle at 50% 48%, #000000, #000000 55%, #ef5f41);
  padding: 3rem 5.4rem; }

.video-item__img {
  position: relative;
  opacity: 0.6; }
  .video-item__img:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4); }

.video-item__link {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  -webkit-box-shadow: 0 1rem 3.3rem #d02703;
  box-shadow: 0 1rem 3.3rem #d02703;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  z-index: 1;
  top: 50%;
  left: 50%;
  border: 0;
  position: absolute;
  width: 11.9rem;
  height: 11.9rem;
  background-image: linear-gradient(to right, #ef5f41, #d02703); }
  .video-item__link svg {
    -moz-transition: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    width: 4rem;
    height: 6.7rem;
    margin-right: -1.8rem;
    color: #fff; }

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  display: none; }
  .embed-container iframe,
  .embed-container object,
  .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

@media screen and (max-width: 767px) {
  .video-item {
    margin: 0 -2rem;
    width: auto;
    padding: 1.2rem 2rem; }
  .video-item__link {
    -webkit-box-shadow: 0 7px 13px 0 #df3733;
    box-shadow: 0 7px 13px 0 #df3733;
    width: 8rem;
    height: 8rem; }
    .video-item__link svg {
      width: 2.7rem;
      height: 4.5rem;
      margin-right: -1.1rem; } }

.modal.navbar-modal {
  display: flex !important; }

.prevent-scroll {
  position: fixed !important;
  width: 100%; }

@media screen and (max-width: 1023px) {
  .navbar-modal {
    -moz-transition: all 0.4s linear;
    -webkit-transition: all 0.4s linear;
    -o-transition: all 0.4s linear;
    transition: all 0.4s linear;
    pointer-events: none;
    -webkit-transition-delay: 600ms,600ms;
    transition-delay: 600ms,600ms;
    display: none;
    overflow: hidden;
    position: fixed;
    width: .1rem;
    height: .1rem;
    top: 7.9rem;
    right: 2rem;
    left: 2rem;
    z-index: 16;
    -webkit-overflow-scrolling: touch;
    outline: 0;
    background-color: transparent; }
    .navbar-modal.in {
      width: calc(100% - 4rem);
      height: calc(100vh - 12.6rem);
      -webkit-transition-delay: 1ms, 1ms;
      transition-delay: 1ms, 1ms;
      pointer-events: auto;
      padding-right: 0 !important;
      background-color: #ef5f41; }
      .navbar-modal.in .navbar-modal__modal-content {
        -moz-transition: all 0.4s ease-in;
        -webkit-transition: all 0.4s ease-in;
        -o-transition: all 0.4s ease-in;
        transition: all 0.4s ease-in; }
        .navbar-modal.in .navbar-modal__modal-content .header__nav-item {
          opacity: 1; }
          .navbar-modal.in .navbar-modal__modal-content .header__nav-item:first-child {
            -webkit-transition-delay: 350ms,350ms;
            transition-delay: 350ms,350ms; }
          .navbar-modal.in .navbar-modal__modal-content .header__nav-item:nth-child(2) {
            -webkit-transition-delay: 400ms,400ms;
            transition-delay: 400ms,400ms; }
          .navbar-modal.in .navbar-modal__modal-content .header__nav-item:nth-child(3) {
            -webkit-transition-delay: 450ms,450ms;
            transition-delay: 450ms,450ms; }
          .navbar-modal.in .navbar-modal__modal-content .header__nav-item:nth-child(4) {
            -webkit-transition-delay: 500ms,500ms;
            transition-delay: 500ms,500ms; }
        .navbar-modal.in .navbar-modal__modal-content .navbar-modal__social-wrap {
          opacity: 1;
          -webkit-transition-delay: 550ms,550ms;
          transition-delay: 550ms,550ms; }
  .navbar-modal__modal-content {
    padding: 2.8rem 1.3rem 6rem;
    width: 100%; }
  .modal-open {
    overflow: hidden; }
    .modal-open .modal {
      overflow-x: hidden;
      overflow-y: auto; }
  .modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll; } }

.is_stuck:not(.mobile-visible) {
  -moz-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  position: fixed;
  top: 9.3rem;
  opacity: 0;
  visibility: hidden; }
  .is_stuck:not(.mobile-visible):before, .is_stuck:not(.mobile-visible):after {
    opacity: 0;
    visibility: hidden; }
  .is_stuck:not(.mobile-visible)._visible {
    opacity: 1;
    visibility: visible; }

@media screen and (max-width: 767px) {
  .is_stuck {
    -moz-transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
    transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    -webkit-transform: translateY(-40px);
    position: fixed;
    background-color: #fff;
    font-weight: 900;
    font-style: normal;
    text-align: center;
    padding: 1.6rem 1rem 1.1rem;
    letter-spacing: 0;
    font-size: 1em;
    color: #ef5f41;
    z-index: 4;
    width: calc(100% - 4rem);
    top: 7.9rem;
    opacity: 0;
    visibility: hidden; }
    .is_stuck:before, .is_stuck:after {
      opacity: 0;
      visibility: hidden; }
    .is_stuck._visible {
      transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
      -webkit-transform: translateY(0);
      opacity: 1;
      visibility: visible; }
    .is_stuck.nav-up {
      transform: translateY(-64px);
      -moz-transform: translateY(-64px);
      -ms-transform: translateY(-64px);
      -o-transform: translateY(-64px);
      -webkit-transform: translateY(-64px); } }

.header {
  -moz-transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  padding: 2.3rem 0;
  position: fixed;
  z-index: 13;
  top: 0;
  right: 0;
  left: 0; }
  .header:before {
    -moz-transition: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(208, 39, 3, 0.89), rgba(239, 95, 65, 0.89));
    opacity: 0;
    visibility: hidden; }
  .header.animation:not(.no-animation) {
    animation-name: fadeInDownHeader;
    animation-duration: 1s; }

.header__center-holder {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: flex-start;
  align-items: center;
  height: 6.3rem; }

.nav-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: center; }

.nav-item {
  padding-right: .7rem; }
  .nav-item:last-child {
    padding-right: 0; }

a.nav-link {
  color: #fff;
  border-color: transparent;
  font-weight: 900;
  padding: .1rem .8rem;
  position: relative;
  text-decoration: none; }
  a.nav-link:after {
    -moz-transition: all 0.4s ease-out;
    -webkit-transition: all 0.4s ease-out;
    -o-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    z-index: -1;
    background-image: linear-gradient(to left, #ef5f41, #d02703); }
  a.nav-link:hover, a.nav-link:focus {
    border-color: transparent;
    color: #fff; }
    a.nav-link:hover:after, a.nav-link:focus:after {
      -moz-transition: all 0.4s ease-in;
      -webkit-transition: all 0.4s ease-in;
      -o-transition: all 0.4s ease-in;
      transition: all 0.4s ease-in;
      opacity: 1; }
  a.nav-link._active {
    border-color: transparent;
    color: #fff; }
    a.nav-link._active:after {
      -moz-transition: all 0.4s ease-in;
      -webkit-transition: all 0.4s ease-in;
      -o-transition: all 0.4s ease-in;
      transition: all 0.4s ease-in;
      opacity: 1; }

.logo {
  border: 0;
  color: #fff;
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0 0 3.5rem; }
  .logo svg,
  .logo img {
    z-index: 1;
    position: relative;
    width: 36.9rem;
    height: 26.3rem; }
  .logo:before {
    background: url(../i/home/logo-shadow-1.png);
    animation-name: logoShadowChange;
    animation-duration: 4s;
    animation-iteration-count: infinite; }
  .logo:after {
    background: url(../i/home/logo-shadow-2.png);
    animation-name: logoHoverShadowChange;
    animation-duration: 4s;
    animation-iteration-count: infinite; }
  .logo:before, .logo:after {
    transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    content: '';
    top: 50%;
    left: 50%;
    position: absolute;
    width: calc(100% + 14rem);
    height: calc(100% + 14rem);
    background-size: contain; }
  .logo:hover, .logo:focus {
    color: #fff !important; }
  .logo._xs svg {
    width: 19.2rem;
    height: 13.7rem; }
  .logo._xs:before, .logo._xs:after {
    width: calc(100% + 7rem);
    height: calc(100% + 7rem); }
  .logo._no-shadow:before, .logo._no-shadow:after {
    display: none; }

@media screen and (max-width: 1023px) {
  .header {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
  .header__center-holder {
    justify-content: flex-start; }
  .navbar-modal__modal-dialog {
    width: 100%; }
  .header__nav {
    padding-bottom: 2.6rem; }
  .header__nav-list {
    font-size: 1.5em;
    line-height: 1.23333em;
    clear: both;
    display: block; }
  .header__nav-item {
    -moz-transition: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    padding: 0 0 .5rem;
    opacity: 0; }
    .header__nav-item:last-child {
      padding-bottom: 0; }
    .header__nav-item:first-child {
      -webkit-transition-delay: 300ms,300ms;
      transition-delay: 300ms,300ms; }
    .header__nav-item:nth-child(2) {
      -webkit-transition-delay: 250ms,250ms;
      transition-delay: 250ms,250ms; }
    .header__nav-item:nth-child(3) {
      -webkit-transition-delay: 200ms,200ms;
      transition-delay: 200ms,200ms; }
    .header__nav-item:nth-child(4) {
      -webkit-transition-delay: 150ms,150ms;
      transition-delay: 150ms,150ms; }
  a.header__nav-link {
    padding: 0;
    display: block;
    margin: 0 -1.3rem;
    padding: .5rem 3.3rem .5rem 1.3rem;
    color: #363636;
    line-height: .9em; }
  .nav-description {
    font-size: 0.53333em;
    line-height: 1em;
    font-weight: 800;
    white-space: normal;
    margin-top: .3rem;
    margin-bottom: .3rem;
    text-shadow: 0 5px 10px #f8c302; }
  .navbar-modal__social-wrap {
    -moz-transition: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    opacity: 0;
    -webkit-transition-delay: 100ms,100ms;
    transition-delay: 100ms,100ms; }
  .logo svg,
  .logo img {
    width: 31rem;
    height: 22rem; }
  .logo:before, .logo:after {
    width: calc(100% + 10rem);
    height: calc(100% + 10rem); }
  .header__btn {
    width: 50%;
    min-width: 17.8rem; } }

@media screen and (min-width: 767px) {
  .header_fixed,
  .header_style-bg {
    padding: 1.5rem 0; }
    .header_fixed:before,
    .header_style-bg:before {
      opacity: 1;
      visibility: visible; }
    .header_fixed .btn,
    .header_style-bg .btn {
      border: .3rem solid #fff;
      background: none;
      line-height: 5.8rem; }
    .header_fixed .nav-link:hover, .header_fixed .nav-link:focus, .header_fixed .nav-link._active,
    .header_style-bg .nav-link:hover,
    .header_style-bg .nav-link:focus,
    .header_style-bg .nav-link._active {
      background-color: #fff;
      color: #d02703; } }

@media screen and (max-width: 767px) {
  .header {
    -moz-transition: none 0.2s ease-out;
    -webkit-transition: none 0.2s ease-out;
    -o-transition: none 0.2s ease-out;
    transition: none 0.2s ease-out;
    height: 8.6rem;
    position: fixed;
    padding-bottom: 0; }
    .header.animation:not(.no-animation) {
      animation-duration: 2s; }
  .header-wrap.nav-up {
    pointer-events: none; }
  .header-wrap.nav-down {
    position: absolute;
    top: 0;
    width: 100%;
    pointer-events: auto; }
    .header-wrap.nav-down .header__center-holder {
      transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
      -webkit-transform: translateY(0); }
      .header-wrap.nav-down .header__center-holder:after {
        opacity: 1;
        visibility: visible; }
  .header__center-holder {
    -moz-transition: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    transform: translateY(calc(-100% - 2.2rem));
    -moz-transform: translateY(calc(-100% - 2.2rem));
    -ms-transform: translateY(calc(-100% - 2.2rem));
    -o-transform: translateY(calc(-100% - 2.2rem));
    -webkit-transform: translateY(calc(-100% - 2.2rem));
    height: 6.4rem; }
    .header__center-holder:after {
      -moz-transition: all 0.2s ease-out;
      -webkit-transition: all 0.2s ease-out;
      -o-transition: all 0.2s ease-out;
      transition: all 0.2s ease-out;
      opacity: 0;
      visibility: hidden;
      content: '';
      position: absolute;
      height: 100%;
      bottom: 0;
      left: 2rem;
      right: 2rem;
      box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.5); }
  .logo svg {
    width: 24.6rem;
    height: 17.4rem; }
  .nav-wrap:before {
    left: 0;
    max-width: 100%; }
  .navbar-modal {
    top: 6.4rem; } }

#wrapper {
  overflow: hidden;
  position: relative;
  width: 100%; }

.center-holder {
  max-width: 102rem;
  margin: 0 auto;
  padding: 0 3rem; }

.body_nav-open {
  overflow: visible !important;
  padding-right: 0 !important; }
  .body_nav-open .header {
    padding-right: 0 !important; }

@media screen and (max-width: 1023px) {
  .center-holder {
    padding-right: 2rem;
    padding-left: 2rem; } }

@media screen and (max-width: 767px) {
  .body_nav-open {
    overflow: hidden !important;
    height: 100%; } }

.footer {
  padding: 6.4rem 0 5.5rem;
  position: relative;
  z-index: 1;
  background-color: #1a0603; }
  .footer:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1a0603; }

.footer__center-holder {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
  align-items: flex-start;
  position: relative;
  z-index: 1; }

.footer__short-col {
  width: 25rem; }

.copyright {
  display: block;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: .5rem;
  color: #d02703; }

.non-disclosure-agreement {
  font-size: 1rem;
  line-height: 1.7rem;
  font-weight: 300;
  padding-bottom: 3.3rem; }
  .non-disclosure-agreement p {
    margin-bottom: .7rem; }

.footer__wide-col {
  width: calc(100% - 32.3rem); }

.footer__nav {
  font-size: 0.8em;
  margin: 9px 0 3.9rem -.4rem; }

.footer__nav-list {
  justify-content: space-between; }

.footer__contact-columns {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: flex-start;
  align-items: flex-start;
  padding-bottom: 2.2rem; }

.footer__contact-col:first-child {
  font-size: 1.4rem;
  line-height: 2.3rem; }

.footer__contact-col .footer-link {
  float: right;
  clear: both; }

.footer-link {
  white-space: nowrap;
  font-weight: 300; }

.footer__address {
  font-style: normal;
  max-width: 35.7rem;
  padding: .3rem 0 0; }

.footer__bottom-columns {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: flex-start;
  align-items: flex-start;
  width: calc(100% - 32.3rem); }

.footer__bottom-col {
  margin-right: 2rem; }
  .footer__bottom-col:last-child {
    margin-right: 0; }

.footer__heading {
  opacity: 0.4; }

.partners {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: flex-start;
  align-items: center; }

.partners__item {
  margin-right: 2.5rem; }
  .partners__item:last-child {
    margin-right: 0; }

a.partners__link {
  border: 0;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center; }
  a.partners__link:hover, a.partners__link:focus {
    color: #fff;
    opacity: 0.7; }
  a.partners__link svg {
    display: block; }
  a.partners__link img {
    display: block;
    max-width: 140px;
    max-height: 60px; }

@media screen and (max-width: 1023px) and (min-width: 767px) {
  .footer {
    padding: 8rem 0 3.8rem; }
  .footer__wide-col {
    width: calc(100% - 23rem); }
  .footer__nav {
    font-size: 1.5rem;
    margin-bottom: 4rem; }
  a.footer__nav-link {
    font-weight: 600;
    padding-left: 0;
    padding-right: 0; }
  .footer__short-col {
    width: 20rem; }
  .footer__bottom-columns {
    width: calc(100% - 23rem); }
  .partners__item {
    margin-right: 1rem; }
  .non-disclosure-agreement {
    padding-bottom: 2rem; } }

@media screen and (max-width: 767px) {
  .footer {
    padding: 8rem 0 3.8rem; }
  a.footer__logo {
    margin-bottom: 7.5rem;
    order: 1; }
  .footer__wide-col {
    width: 100%;
    order: 2; }
  .footer__nav {
    font-size: 2.1em;
    display: block;
    margin: 0 0 3.6rem; }
  .footer__nav-list {
    display: block; }
  .footer__nav-item {
    padding: 0;
    width: 100%;
    margin-bottom: 2.9rem;
    opacity: 1;
    visibility: visible; }
  a.footer__nav-link {
    margin: 0 -2rem;
    padding: .5rem 2rem;
    display: block;
    font-size: 1em;
    line-height: 0.90476em; }
  .footer__contact-columns {
    display: block;
    font-weight: 300;
    padding-bottom: 3.6rem; }
  .footer__contact-col {
    width: 100%;
    line-height: 1.6em;
    padding-bottom: 3.5rem; }
    .footer__contact-col:after {
      content: " ";
      display: block;
      clear: both; }
    .footer__contact-col:last-child {
      padding-bottom: 0; }
    .footer__contact-col:first-child {
      font-size: 1em;
      line-height: 1.6em; }
    .footer__contact-col .footer-link {
      float: left;
      clear: left; }
  .footer-link {
    color: #fff;
    margin-bottom: .2rem; }
    .footer-link.tel-link {
      border: none; }
  .footer__bottom-columns {
    order: 3;
    display: block;
    width: 100%;
    padding: 0 0 3.4rem; }
  .footer__bottom-col {
    padding-bottom: 3.8rem; }
    .footer__bottom-col:last-child {
      padding-bottom: 0; }
  .partners {
    margin-top: 0;
    flex-wrap: wrap;
    justify-content: flex-start; }
  .partners__item_full-width-xs {
    width: 100%;
    margin-bottom: 3.3rem; }
  .footer__short-col {
    order: 4;
    margin-top: 0;
    width: 100%; }
  .copyright {
    font-size: 0.7em;
    line-height: 1.64286em; }
  .non-disclosure-agreement {
    font-size: 0.7em;
    line-height: 1.64286em;
    padding-bottom: 2.9rem; }
    .non-disclosure-agreement p {
      margin-bottom: 1.1rem; } }

@media screen and (max-width: 374px) {
  .footer__main-nav .main-nav__list {
    font-size: 2em; } }

.s-404 {
  padding: 15rem 0 5rem; }
  .s-404:after {
    content: " ";
    display: block;
    clear: both; }
  .s-404 .s-title {
    margin-bottom: 3rem; }
  .s-404 p {
    font-size: 0.9em; }

@media screen and (max-width: 767px) {
  .s-404 p {
    font-size: 0.8em; } }

.s-intro {
  padding: 11.2rem 0 0;
  position: relative;
  height: 100vh;
  min-height: 72.3rem; }
  .s-intro .swiper-container {
    height: calc(100% - 1rem);
    margin-top: -4rem;
    padding-top: 4rem; }

.swiper-container .s-intro__contact {
  top: calc(50% + 2rem); }

.slideInUp {
  -moz-transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  position: relative;
  z-index: 2;
  width: 100%; }
  .slideInUp.animation {
    animation-name: fadeInUp;
    animation-duration: 1.5s; }

.s-intro__img {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-position: center center; }
  .s-intro__img:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4); }
  .s-intro__img._no-overlay:after, .s-intro__img._empty:after {
    display: none; }

.s-intro__center-holder {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative; }

.swiper-slide .s-intro__center-holder {
  margin: 0; }

.s-intro__contact {
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
  width: 36.9rem;
  position: absolute;
  top: 50%;
  left: calc((100% - 96rem)/2);
  z-index: 2; }
  .s-intro__contact.animation {
    animation-name: introContactAnimation;
    animation-duration: 1.5s; }
  .s-intro__contact._style-black {
    color: #363636; }
    .s-intro__contact._style-black .s-intro__address {
      color: #363636; }
  .s-intro__contact._static {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    position: static; }
    .s-intro__contact._static.animation {
      animation-name: introStaticContactAnimation;
      animation-duration: 1.5s; }
  .s-intro__contact._top-right {
    max-width: 38.8rem;
    padding-top: 3.2rem;
    width: auto; }
    .s-intro__contact._top-right.animation {
      animation-name: fadeInRightContact;
      animation-duration: 4s; }

.social-wrap + .s-intro__address-wrap {
  padding-top: 4.7rem; }

.s-intro__address-wrap .icon {
  margin-bottom: 1.2rem; }

.s-intro__address-wrap + .social-wrap {
  padding-top: 3.3rem; }

.s-intro__address {
  font-size: 0.7em;
  margin-top: -.6rem; }

.swiper-wrapper.animation {
  animation-name: introGalleryAnimation;
  animation-duration: 1.5s; }
  .swiper-wrapper.animation + .swiper-pagination {
    animation-name: paginationAnimation;
    animation-duration: 1.5s; }

.swiper-wrapper .s-intro__gallery-col:before {
  display: none; }

.gallery-col-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: flex-end;
  height: 100%;
  position: relative;
  z-index: 1;
  width: 50%; }
  .gallery-col-wrap .s-intro__gallery-col {
    width: calc(100% - 7rem); }
  .gallery-col-wrap.animation {
    animation-name: introGalleryAnimation;
    animation-duration: 1.5s; }
  .gallery-col-wrap:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 5rem;
    left: 0;
    width: .1rem;
    background-color: #fff;
    opacity: 0.4; }

.s-intro__gallery-col {
  text-align: left;
  width: calc(50% - 7rem);
  position: relative;
  z-index: 1; }
  .s-intro__gallery-col.animation {
    animation-name: introGalleryAnimation;
    animation-duration: 1.5s; }

.main-event {
  padding: 0 0 4rem 7rem;
  font-weight: 800;
  letter-spacing: -.1rem; }

.main-event .link-white {
  max-width: 100%; }
  .main-event .link-white svg {
    display: block;
    max-width: 100%;
    height: auto; }

.main-event__date {
  margin-bottom: 3.1rem;
  margin-left: -7rem; }

.scroll-down {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  color: #ef5f41;
  font-weight: 600;
  padding-top: 2.7rem; }
  .scroll-down + .icon-moscow-city {
    color: #ef5f41;
    margin-top: -6.3rem; }

.scroll-down__text {
  font-size: 0.9em; }

.icon-mouse-next {
  margin-right: 1.2rem; }

.prize .scroll-down {
  padding-top: 6.3rem; }

.prize-wrap {
  margin-left: -.6rem;
  width: 96%; }
  .prize-wrap.ff .prize__value {
    font-size: 5.35em; }

.prize__value {
  margin-bottom: 0;
  display: inline-block;
  vertical-align: top;
  letter-spacing: -.2rem;
  font: italic 900 110px/1em "helveticaneuecyr", sans-serif;
  stroke-width: 4px;
  fill: none;
  width: 100%;
  stroke: #ef5f41;
  margin-bottom: -.7rem; }

.prize__currency {
  font-size: 1.5em;
  line-height: 0.86667em;
  text-align: right;
  font-weight: 900;
  font-style: italic;
  margin: -1rem 0 1.6rem 0;
  display: block;
  text-align: right;
  color: #ef5f41; }

.prize__note {
  font-size: 1.2em;
  line-height: 1.33333em;
  font-weight: 900;
  padding-top: 1.2rem;
  margin-bottom: 0; }

.s-intro .registration-steps li {
  margin-bottom: .8rem; }

.registration-steps {
  margin: 0;
  padding: 0;
  list-style: none;
  padding-bottom: 3.8rem;
  width: 100%;
  margin-left: -1rem; }
  .registration-steps li {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
    margin-bottom: 2rem;
    position: relative; }
    .registration-steps li:last-child {
      margin-bottom: 0; }

.registration-steps__number {
  font: italic 900 49px/1em "helveticaneuecyr", sans-serif;
  stroke-width: 2px;
  fill: none;
  stroke: #ef5f41;
  margin: -.8rem 1.7rem 0 0;
  min-width: 42px; }

.s-intro__address-wrap {
  display: flex;
  align-items: center; }

.social-wrap + .s-intro__address-wrap a {
  flex: 0 0 100px;
  max-width: 100px;
  margin-right: 15px; }
  .social-wrap + .s-intro__address-wrap a img {
    display: block;
    max-width: 100%; }

.s-intro__address {
  margin: 0;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

@media screen and (max-width: 1023px) {
  .s-intro__contact {
    left: 2rem; }
  .s-intro__gallery-col {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
    align-items: flex-start;
    width: calc(50% - 4rem); }
    .s-intro__gallery-col .barber {
      width: 47%;
      padding-right: 1.5rem; }
      .s-intro__gallery-col .barber:first-of-type {
        width: 53%; }
  .gallery-col-wrap .s-intro__gallery-col {
    width: calc(100% - 4rem); }
  .prize__value {
    stroke-width: 4px; }
  .prize__currency {
    font-size: 1.2em; }
  .icon-moscow-city {
    max-width: 100%; }
  .main-event {
    padding-left: 3rem; }
  .main-event__date {
    margin-left: -3rem; } }

@media screen and (max-width: 767px), screen and (max-height: 375px) {
  .s-intro {
    padding: 10.4rem 0 7.4rem;
    height: auto; }
    .s-intro._home {
      height: auto;
      min-height: 1px; }
    .s-intro .swiper-container,
    .s-intro .swiper-wrapper,
    .s-intro .swiper-slide {
      height: auto;
      display: block;
      overflow: visible; }
    .s-intro .swiper-slide {
      padding-bottom: 5rem;
      overflow: visible; }
      .s-intro .swiper-slide:last-child {
        padding-bottom: 0; }
  .logo {
    margin: 0 0 3rem; }
  .social-wrap + .s-intro__address-wrap {
    padding-top: 4.1rem; }
  .s-intro__img {
    height: auto; }
  .s-intro__contact {
    transform: translateX(0) translateY(0);
    -moz-transform: translateX(0) translateY(0);
    -ms-transform: translateX(0) translateY(0);
    -o-transform: translateX(0) translateY(0);
    -webkit-transform: translateX(0) translateY(0);
    position: relative;
    top: auto;
    left: auto;
    width: 100%;
    padding: 0 2rem 7.7rem; }
    .s-intro__contact._static {
      padding-bottom: 1.3rem; }
    .s-intro__contact._top-right.animation {
      animation-name: slideInLeftContactXS;
      animation-duration: 2.5s; }
  .s-intro__center-holder {
    padding: 0 2rem;
    height: auto;
    display: block;
    overflow: visible; }
  .s-intro__gallery-col {
    width: 100%;
    padding: 0; }
    .s-intro__gallery-col .barber {
      margin-bottom: 0; }
  .s-intro__center-holder > .s-intro__contact {
    padding-right: 0;
    padding-left: 0; }
  .gallery-col-wrap {
    width: 100%; }
    .gallery-col-wrap:before {
      display: none; }
    .gallery-col-wrap .s-intro__gallery-col {
      width: 100%; }
  .main-event {
    padding: 0; }
  .main-event__date {
    margin-left: 0; }
  .prize-wrap {
    width: 30rem;
    min-width: 28rem; }
  .prize__value {
    height: 7.6rem; }
  .prize__currency {
    letter-spacing: 0;
    margin: 2px 0 .6rem; }
  .prize .scroll-down {
    padding-top: 5.3rem; }
  .scroll-down + .icon-moscow-city {
    margin-top: -5.1rem; } }

.s-schedule {
  background-color: #1a0603; }

.s-schedule__head {
  margin-bottom: .7rem; }

.s-shedule__inner-head {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start; }

.s-schedule__frame {
  padding: 0 0 6.6rem; }
  .s-schedule__frame + .invitation-area {
    padding-top: 1.6rem; }

.s-schedule__block {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: flex-start;
  align-items: flex-start; }

.schedule-item-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: center;
  margin-bottom: 3.6rem;
  border-style: solid;
  border-width: 4px;
  border-image-source: linear-gradient(to top, #d02703, #ef5f41);
  border-image-slice: 1;
  position: relative; }
  .schedule-item-wrap + h2 {
    margin-top: 3rem; }
  .schedule-item-wrap:last-of-type {
    margin-bottom: 0; }

.schedule-item__head {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  font-size: 1.5em;
  line-height: 1em;
  padding-right: 2rem; }

.schedule-item__date {
  background-image: linear-gradient(to left, #ef5f41, #ef5f41);
  color: #000;
  font-weight: 900;
  padding: 2.1rem 2.4rem 1.9rem; }

.schedule-item__title {
  font-size: 1em;
  line-height: 1em;
  padding: 2rem 0 2rem 2.2rem; }

.schedule-item__frame {
  padding: 2.2rem 2rem 4rem;
  position: relative;
  overflow: hidden; }
  .schedule-item__frame + .schedule-item__frame {
    padding-top: 3.3rem; }
  .schedule-item__frame:last-of-type:after {
    display: none; }
  .schedule-item__frame:after {
    content: '';
    background-image: linear-gradient(to right, #d02703, #ef5f41);
    position: absolute;
    bottom: 0;
    left: 16rem;
    right: 8.2rem;
    height: 1px; }

.schedule-item__description {
  padding: 1.9rem 6rem 0 4.5rem;
  width: calc(100% - 34rem);
  float: left; }

.schedule-item__title {
  margin-bottom: 0; }
  .schedule-item__title + .schedule-item__partner {
    margin-top: 1.4rem; }

@media screen and (max-width: 1023px) {
  .schedule-item__description {
    width: calc(100% - 20rem);
    padding-right: 0; }
  .schedule-item__frame:after {
    left: 2rem;
    right: 2rem; } }

@media screen and (max-width: 767px) {
  .s-schedule__frame {
    position: relative; }
  .schedule-item {
    flex-wrap: wrap; }
  .schedule-item-wrap {
    display: block; }
  .schedule-item__head {
    display: block;
    font-size: 1.5em;
    padding-right: 0; }
  .schedule-item__date {
    display: block;
    text-align: center; }
  .schedule-item__title {
    padding: 4.1rem 2rem 0 1.5rem;
    margin-bottom: 3.5rem; }
  .schedule-item__frame {
    display: block;
    padding: 0 1.5rem 3rem; }
    .schedule-item__frame + .schedule-item__frame {
      padding-top: 3.6rem; }
    .schedule-item__frame:after {
      right: 1.5rem;
      left: 1.5rem; }
  .schedule-item__description {
    font-size: 0.8em;
    line-height: 1.5em;
    width: 100%;
    padding: 4rem 0 0; } }

.plan-wrap {
  padding-top: 8.4rem; }
  .plan-wrap img {
    display: block;
    max-width: 100%; }

.s-contact {
  height: 70rem; }
  .s-contact._inner {
    height: auto;
    min-height: 4.5rem;
    background-color: #1a0603;
    color: #fff;
    padding-top: 0;
    overflow: hidden; }
    .s-contact._inner .map-area {
      position: static; }

.s-contact__head {
  position: relative;
  z-index: 1; }

.contact-block {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  -webkit-box-shadow: 0 10px 20px 0 #ef5f41;
  box-shadow: 0 10px 20px 0 #ef5f41;
  max-width: 48.9rem;
  background-color: #ef5f41;
  padding: 3.5rem 2.2rem 2rem;
  line-height: 1.6em;
  position: absolute;
  left: calc((100% - 96rem)/2);
  z-index: 1;
  font-size: 0.8em;
  bottom: 9rem; }
  .contact-block .icon-print-house-logo {
    min-width: 13.4rem;
    height: 11.9rem; }

.address-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: center;
  font-size: 1em;
  line-height: 1.625em;
  font-weight: 800; }
  .address-wrap address {
    width: calc(100% - 6.4rem);
    padding-left: 4rem;
    font-style: normal; }
  .address-wrap._xs {
    padding-bottom: 0; }
    .address-wrap._xs address {
      font-size: 0.77778em;
      line-height: 1.64286em;
      font-weight: 400;
      padding-left: 2.2rem; }
    .address-wrap._xs a {
      flex: 0 0 60px;
      max-width: 60px; }
  .address-wrap a {
    flex: 0 0 100px;
    max-width: 100px; }
    .address-wrap a img {
      display: block;
      max-width: 100%; }

.map-area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .map-area._short {
    height: 45rem; }
  .map-area #map {
    height: 100%; }
  .map-area * {
    cursor: url(../i/cursors/cursor-default.cur), auto !important; }
  .map-area + .center-holder {
    padding-top: 8.5rem; }

@media screen and (max-width: 1023px) {
  .contact-block {
    left: 2rem; } }

@media screen and (max-width: 767px) {
  .s-contact {
    height: auto;
    min-height: 70rem;
    background-color: #e8e8e8;
    padding-right: 2rem;
    padding-left: 2rem;
    padding-bottom: 0; }
    .s-contact._inner {
      padding-right: 0;
      padding-left: 0;
      padding-bottom: 5rem; }
  h2.center-holder {
    margin-bottom: 4.1rem; }
  .s-contact__head {
    padding: 0; }
  .map-area {
    position: static;
    width: 100%;
    height: 40rem; }
  .contact-block {
    max-width: 100%;
    padding: 2.7rem 2.2rem 4.2rem;
    position: relative;
    top: auto;
    left: auto;
    bottom: auto; }
    .contact-block .icon-print-house-logo {
      min-width: 10rem;
      max-width: 10rem; }
  .map-area {
    margin: 0 -2rem;
    width: auto; }
  .contact-block__address {
    font-size: 1em; }
  .contact-block__subway-title {
    font-size: 1em; }
  .address-wrap {
    font-size: 1em; }
    .address-wrap address {
      width: calc(100% - 10rem);
      padding-left: 2.7rem; }
    .address-wrap._xs {
      flex-wrap: wrap; }
      .address-wrap._xs address {
        font-size: 1em;
        line-height: 1.6em;
        font-weight: 300;
        width: 100%;
        padding: .8rem 0 0; } }

@media screen and (max-width: 374px) {
  .address-wrap {
    flex-wrap: wrap; }
    .address-wrap address {
      width: 100%;
      padding: 2rem 0 0; } }

.s-intro__address-wrap .link-base {
  flex: 0 0 100px;
  max-width: 100px;
  margin-right: 15px; }
  .s-intro__address-wrap .link-base img {
    display: block;
    width: 100%; }

.s-partners {
  background-color: #e8e8e8; }

.organizers-wrap {
  padding-top: 4.8rem; }

.organizers {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  margin: 0 -1rem -4rem; }

.organizer-item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 30.1rem;
  width: calc(25% - 2rem);
  margin: 0 1rem;
  border-style: solid;
  border-width: 1px;
  border-image-source: linear-gradient(to top, #ef5f41, #ef5f41);
  border-image-slice: 1;
  padding: 2rem;
  margin-bottom: 4rem; }

.organizer-item__logo {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 12.5rem;
  width: 100%;
  color: #fff;
  margin-bottom: 4rem; }
  .organizer-item__logo img {
    display: block;
    max-width: 95%;
    max-height: 95%; }
  .organizer-item__logo .icon-logo-el {
    width: 12rem;
    height: 6.3rem; }
  .organizer-item__logo .icon-logo-lock-stock-barrel {
    width: 12.6rem;
    height: 12.6rem; }
  .organizer-item__logo .icon-logo-4system {
    width: 11rem;
    height: 11.8rem; }
  .organizer-item__logo .icon-logo-feather {
    width: 16.9rem;
    height: 4.5rem; }
  .organizer-item__logo .icon-logo-reuzel {
    width: 11.6rem;
    height: 12.6rem; }
  .organizer-item__logo .icon-logo-simushka {
    width: 14rem;
    height: 1.5rem; }

.organizer-item__name {
  text-align: center; }

@media screen and (max-width: 767px) {
  .organizer-item {
    width: calc(50% - 1.2rem);
    margin: 0 .6rem 2rem;
    height: 21.7rem; }
  .organizers {
    margin: 0 -.6rem; }
  .organizer-item__logo {
    height: 10rem;
    margin-bottom: 1.5rem; }
    .organizer-item__logo svg {
      max-height: 100%; } }

.s-purchase {
  background-color: #fff;
  padding-bottom: 8.8rem; }

.tickets-tabset {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: stretch;
  text-align: center;
  margin-bottom: 2rem; }

.tickets-tabset__item {
  width: 50%;
  font-size: 1.2em;
  font-weight: 900;
  height: 7rem; }
  .tickets-tabset__item.active {
    background-color: #d02703; }
    .tickets-tabset__item.active .tickets-tabset__link {
      color: #fff; }

.tickets-tabset__price {
  text-transform: uppercase;
  display: block;
  margin: -.2rem 0 0;
  font-weight: 600; }

.tickets-tabset__link {
  border: none;
  height: 100%;
  padding: .7rem 0 .8rem;
  border: 0.3rem solid #d02703;
  display: block;
  color: #d02703;
  text-decoration: none; }
  .tickets-tabset__link:hover, .tickets-tabset__link:focus {
    color: #d02703;
    background-color: rgba(208, 39, 3, 0.2);
    border-color: #d02703; }
  .tickets-tabset__link._disable {
    background-color: #e8e8e8;
    pointer-events: none;
    border-color: #e8e8e8;
    color: #c7c7c7; }

.tab-pane {
  display: none;
  padding-top: 4rem; }
  .tab-pane.active {
    display: block; }

.ticket-items {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: stretch;
  margin: 0 -1rem; }

.ticket-item {
  width: 40.7rem;
  max-width: 50%;
  padding: 0 1rem;
  text-align: center; }

.ticket-item_selected .ticket-item__link {
  background-color: #ef5f41;
  border-color: #ef5f41;
  color: #fff; }
  .ticket-item_selected .ticket-item__link .ticket-item__date {
    color: #fff; }

.ticket-item__link {
  background-color: #ef5f41;
  color: #363636;
  display: block;
  border: 0.3rem solid #ef5f41;
  padding: 4.9rem 0 0;
  position: relative; }
  .ticket-item__link:before {
    -moz-transition: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-image: linear-gradient(to bottom, #d02703, #ef5f41);
    opacity: 0;
    visibility: hidden; }
  .ticket-item__link:hover, .ticket-item__link:focus {
    -webkit-box-shadow: 0 10px 20px 0 #ef5f41;
    box-shadow: 0 10px 20px 0 #ef5f41;
    color: #363636;
    background-color: #ef5f41;
    border-color: #ef5f41; }
    .ticket-item__link:hover:before, .ticket-item__link:focus:before {
      opacity: 1;
      visibility: visible; }
    .ticket-item__link:hover .ticket-item__purchase-info:before, .ticket-item__link:focus .ticket-item__purchase-info:before {
      opacity: 1;
      visibility: visible; }

.ticket-item__title {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: flex-end;
  font-weight: 600;
  padding: 0 2.5rem;
  line-height: 1em;
  margin: 0 0 2rem;
  position: relative;
  z-index: 1; }

.ticket-item__description {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  padding-bottom: 3.4rem;
  font-size: 1em;
  line-height: 1.2;
  position: relative;
  z-index: 1; }

.ticket-item__date {
  -moz-transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  color: #363636;
  display: block;
  padding-bottom: 5rem; }

.ticket-item__purchase-info {
  -moz-transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  background: #fff;
  background: linear-gradient(-225deg, transparent 15px, #fff 0);
  height: 17.5rem;
  padding: 0 1rem;
  position: relative; }
  .ticket-item__purchase-info:before {
    -moz-transition: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ef5f41;
    opacity: 0;
    visibility: hidden; }
  .ticket-item__purchase-info p {
    margin: -.6rem 0 0;
    position: relative;
    z-index: 1; }

.ticket-price {
  font-size: 2.4em;
  display: block;
  margin: -1.6rem 0 -1.8rem; }

.purchase-form {
  max-width: 81.6rem; }

.s-purchase__h3 {
  font-size: 1.5em; }

.tickets-tab-content {
  background: url(../i/buy-ticket/form-gradient.png);
  padding: 0 2rem 8rem; }

.ticket-info__col {
  padding-right: 3rem; }

.ticket-info {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: flex-start;
  align-items: stretch; }

.ticket-info__price-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex: 0 0 30rem;
  text-align: center; }

.ticket-step {
  padding-bottom: 5.6rem;
  padding-top: 2.7rem; }
  .ticket-step:last-of-type {
    padding-bottom: 0; }

@media screen and (max-width: 1023px) {
  .ticket-item__link {
    font-size: 0.9em; }
  .ticket-item__date {
    padding-bottom: 3rem; } }

@media screen and (max-width: 767px) {
  .tickets-tabset {
    flex-wrap: wrap; }
  .s-purchase__form-wrap {
    padding-top: 4rem; }
  .tickets-tabset__item {
    width: 100%;
    min-height: 7rem; }
  .ticket-info__price-wrap {
    flex: auto; }
  .ticket-items {
    flex-wrap: wrap;
    margin: 0 -.7rem -2rem; }
  .ticket-item {
    width: 50%;
    padding: 0 .7rem 2rem; }
  .ticket-item__link {
    font-size: 0.8em;
    padding-top: 3.1rem; }
  .ticket-item__title {
    line-height: 1.25em;
    margin: 0 0 .8rem; }
  .ticket-item__description {
    padding-right: .5rem;
    padding-left: .5rem; }
  .ticket-item__date {
    padding-bottom: 2.3rem; }
  .ticket-item__purchase-info {
    font-size: 1.125em;
    background-color: #fff;
    background: linear-gradient(-225deg, transparent 19px, #fff 0); }
  .ticket-item__main-price {
    font-size: 2.22222em;
    margin: -1.2rem 0 -1.4rem; }
  .ticket-item__regular-price {
    padding-top: 1.4rem;
    line-height: 1.16667em; }
    .ticket-item__regular-price .text-danger {
      display: block; }
  .s-purchase._no-top-padding {
    margin-top: -2.5rem; }
  .s-purchase__h3 {
    font-size: 1.3em;
    line-height: 0.88462em;
    margin-bottom: 3rem; }
  .header_style-purchase {
    width: 100%; }
    .header_style-purchase .menu-opener {
      width: 100%; }
  .tickets-tab-content {
    margin: 0 -2rem; }
  .tab-pane {
    padding-top: 2rem; }
  .ticket-info {
    flex-wrap: wrap; }
  .ticket-info__col {
    padding-right: 0; } }

@media screen and (max-width: 374px) {
  .ticket-item {
    width: 100%;
    max-width: 100%; } }

.s-barbers {
  background-color: #1a0603; }

.barber {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: center;
  text-align: left;
  margin-bottom: 3rem; }
  .barber:last-child {
    margin-bottom: 0; }
  .barber._xs {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
    width: 34rem;
    margin-bottom: 0;
    float: left; }
    .barber._xs .barber-avatar {
      min-width: 10.8rem;
      max-width: 10.8rem;
      height: 10.8rem; }
    .barber._xs .barber__name {
      font-size: 1em; }
    .barber._xs .barber__info {
      padding-top: 1.7rem; }
    .barber._xs .barber__job-place {
      margin-bottom: 2rem; }

.barber-avatar {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
  min-width: 15.2rem;
  max-width: 15.2rem;
  border: 0.4rem solid #ef5f41;
  margin-left: -.4rem; }

.barber-square__avatar {
  min-width: 21rem;
  border: 0.5rem solid #ef5f41;
  margin-bottom: 1.1rem; }
  .barber-square__avatar img {
    display: block;
    width: 100%;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%); }

.barber__info {
  padding-left: 3.7rem; }

.barber__name {
  font-size: 1.2em;
  line-height: 1.08333em;
  font-weight: 900;
  margin-bottom: .9rem; }

.barber-square__name {
  font-weight: 600;
  margin-bottom: .8rem; }

.barber__job-place {
  margin-bottom: 1.3rem;
  line-height: 1em; }

.barber-square__job-place {
  opacity: 0.6;
  font-size: 0.7em;
  margin-bottom: 1rem; }

.barbers-group-wrap {
  width: 34rem;
  padding-top: .9rem;
  float: left; }
  .barbers-group-wrap .barbers-group {
    padding-bottom: 4.7rem; }

.barbers-group {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: center;
  width: 100%; }
  .barbers-group .barber-avatar {
    min-width: 10.2rem;
    max-width: 10.2rem;
    height: 10.2rem;
    margin-right: -2.6rem; }
    .barbers-group .barber-avatar:first-child {
      z-index: 4; }
    .barbers-group .barber-avatar:nth-child(2) {
      z-index: 3; }
    .barbers-group .barber-avatar:nth-child(3) {
      z-index: 2; }
    .barbers-group .barber-avatar:last-child {
      z-index: 1; }

.barber-columns {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
  align-items: flex-start;
  margin-bottom: -6rem; }

.barber-square {
  flex: 0 0 21rem;
  margin: 0 5rem 6rem 0; }
  .barber-square:nth-child(3n) {
    margin-right: 0; }

@media screen and (max-width: 1023px) {
  .barbers-group .barber-avatar {
    margin-right: -4rem; }
  .barber {
    flex-wrap: wrap; }
    .barber._xs {
      flex-wrap: wrap;
      width: 20rem; }
  .barber-avatar {
    min-width: 13.4rem;
    max-width: 13.4rem; }
  .barber__info {
    width: 100%;
    padding: 1.5rem 0 0; }
  .barber__name {
    font-size: 1em;
    line-height: 1.3em; }
  .barber__job-place {
    font-size: 0.7em; }
  .barbers-group-wrap {
    width: 20rem;
    min-width: 1px;
    padding-top: 0; }
    .barbers-group-wrap .barbers-group {
      flex-wrap: wrap; }
      .barbers-group-wrap .barbers-group .barber-avatar {
        margin: 0 -1.9rem 1.1rem 0; }
        .barbers-group-wrap .barbers-group .barber-avatar:nth-child(2) {
          margin-right: 0; } }

@media screen and (max-width: 767px) {
  .barber {
    margin-bottom: 4rem; }
    .barber._xs .barber__job-place {
      font-size: 1em;
      margin-bottom: 2.6rem; }
  .barber__info {
    padding-top: 1.3rem; }
  .barber__job-place {
    margin-bottom: 1.6rem; }
  .barbers-group .barber-avatar {
    margin-right: -2.5rem; }
  .barbers-group-wrap {
    width: 100%; }
    .barbers-group-wrap .barbers-group {
      padding-bottom: 2rem; }
      .barbers-group-wrap .barbers-group .barber-avatar {
        margin: 0 1.1rem 1.1rem 0; }
        .barbers-group-wrap .barbers-group .barber-avatar:nth-child(2) {
          margin-right: 0; }
  .barber-columns {
    justify-content: flex-start;
    margin: 0 -1rem -3rem; }
  .barber-square {
    margin: 0 1rem 3rem;
    width: calc(50% - 2rem);
    flex: auto; }
    .barber-square:last-child {
      margin: 0 1rem 3rem; }
    .barber-square:nth-child(3n) {
      margin-right: 1rem; }
  .barber-square__avatar {
    width: 13rem;
    min-width: 13rem; }
  .barber-square__info {
    width: 13rem; } }

@media screen and (max-width: 374px) {
  .barbers-group .barber-avatar {
    margin-right: -4.5rem; } }

.tickets-tabset__item + .tickets-tabset__item {
  margin-left: -0.3rem; }

@media (max-width: 767px) {
  .tickets-tabset__item + .tickets-tabset__item {
    margin-left: 0; }
  .tickets-tabset__item + .tickets-tabset__item {
    margin-top: -0.3rem; } }
