.btn {
	display: inline-block;
	vertical-align: top;
	white-space: nowrap;
	font: 800 1em $main-font;
	text-align: center;
	color: #fff;
	height: 6.4rem;
	line-height: 6.4rem;
	border: 0;
	max-width: 100%;
	-webkit-backface-visibility: hidden;
	z-index: 1;
	position: relative;
	padding: 0 2rem;
	text-decoration: none;
	&:after{
		@include gradient-opacity;
	}
	&:hover,
	&:focus{
		color: #fff;
		&:after {
			@include gradient-opacity-hover;
		}
	}
	&._default{
		background-image: linear-gradient(to left, $main-color-light, $main-color-dark);
		&:after{
			background: #fff;
		}
		&:hover,
		&:focus{
			color: $main-color-dark;
		}
	}
	&._primary{
		@include box-shadow(0 10px 40px 0 $main-color-light);
		background-color: $main-color-dark;
		color: $color-white;
		&:hover,
		&:focus{
			background-color: $color-warning;
			color: $color-text-base;
		}
	}
	&._wide{
		min-width: 40.3rem;
		padding: 0 3rem;
	}
	&._success{
		background-color: #41e163;
		color: $color-text-base;
	}
	&._danger{
		background-color: #df3733;
	}
}
.link-holder{
	position: relative;
	z-index: 2;
}

@media screen and (max-width:$point-3) {
	.btn{
		&._wide{
			width: 100%;
			min-width: 1px;
			max-width: 48rem;
			display: block;
			margin-right: auto;
			margin-left: auto;
			padding: 0 2rem;
		}
	}
}