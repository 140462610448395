.header {
	@include transition;
	padding: 2.3rem 0;
	position: fixed;
	z-index: 13;
	top: 0;
	right: 0;
	left: 0;
	&:before{
		@include transition;
		content:'';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: linear-gradient(to bottom, rgba($main-color-dark,0.89), rgba($main-color-light,0.89));
		opacity:0;
		visibility: hidden;
	}
	&.animation:not(.no-animation){
		animation-name: fadeInDownHeader;
		animation-duration: 1s;
	}
}
.header__center-holder{
	@include flex_block(row, nowrap, space-between, center, flex-start);
	height: 6.3rem;
}
.nav-list{
	@include reset-ul;
	@include flex_block(row, nowrap, flex-start, center, flex-start);
}
.nav-item{
	padding-right: .7rem;
	&:last-child{
		padding-right: 0;
	}
}
a.nav-link{
	color: #fff;
	border-color: transparent;
	font-weight: 900;
	padding: .1rem .8rem;
	position: relative;
	text-decoration: none;
	&:after{
		@include gradient-opacity;
		z-index: -1;
		background-image: linear-gradient(to left, $main-color-light, $main-color-dark);
	}
	&:hover,
	&:focus{
		border-color: transparent;
		color: #fff;
		&:after {
			@include gradient-opacity-hover;
		}
	}
	&._active{
		border-color: transparent;
		color: #fff;
		&:after {
			@include gradient-opacity-hover;
		}
	}
}
.logo{
	border: 0;
	color: #fff;
	display: inline-block;
	vertical-align: top;
	position: relative;
	margin: 0 0 3.5rem;
	svg,
	img{
		z-index: 1;
		position: relative;
		width: 36.9rem;
		height: 26.3rem;
	}
	&:before{
		background: url(../i/home/logo-shadow-1.png);

		animation-name: logoShadowChange;
		animation-duration: 4s;
		animation-iteration-count: infinite;
	}
	&:after{
		background: url(../i/home/logo-shadow-2.png);
		animation-name: logoHoverShadowChange;
		animation-duration: 4s;
		animation-iteration-count: infinite;
	}
	&:before,
	&:after{
		@include transform(translateX(-50%) translateY(-50%));
		content:'';
		top: 50%;
		left: 50%;
		position: absolute;
		width: calc(100% + 14rem);
		height: calc(100% + 14rem);
		background-size: contain;
	}
	&:hover,
	&:focus{
		color: #fff !important;
	}
	&._xs{
		svg{
			width: 19.2rem;
			height: 13.7rem;
		}
		&:before,
		&:after{
			width: calc(100% + 7rem);
			height: calc(100% + 7rem);
		}
	}
	&._no-shadow{
		&:before,
		&:after{display: none;}
	}
}
@media screen and (max-width:$point-2) {
	.header{
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}
	.header__center-holder{
		justify-content: flex-start;
	}
	.navbar-modal__modal-dialog{
		width: 100%;
	}
	.header__nav{
		padding-bottom: 2.6rem;
	}
	.header__nav-list{
		font-size: em(30);
		line-height: em(37,30);
		clear: both;
		display: block;
	}
	.header__nav-item{
		@include transition;
		padding: 0 0 .5rem;
		opacity:0;
		&:last-child{
			padding-bottom: 0;
		}
		&:first-child{
			-webkit-transition-delay: 300ms,300ms;
			transition-delay: 300ms,300ms;
		}
		&:nth-child(2) {
			-webkit-transition-delay: 250ms,250ms;
			transition-delay: 250ms,250ms;
		}
		&:nth-child(3) {
			-webkit-transition-delay: 200ms,200ms;
			transition-delay: 200ms,200ms;
		}
		&:nth-child(4) {
			-webkit-transition-delay: 150ms,150ms;
			transition-delay: 150ms,150ms;
		}
	}
	a.header__nav-link{
		padding: 0;
		display: block;
		margin: 0 -1.3rem;
		padding: .5rem 3.3rem .5rem 1.3rem;
		color: $color-text-base;
		line-height: .9em;
	}
	.nav-description{
		font-size: em(16,30);
		line-height: em(16,16);
		font-weight: 800;
		white-space: normal;
		margin-top: .3rem;
		margin-bottom: .3rem;
		text-shadow: 0 5px 10px #f8c302;
	}
	.navbar-modal__social-wrap{
		@include transition;
		opacity:0;
		-webkit-transition-delay: 100ms,100ms;
		transition-delay: 100ms,100ms;
	}
	.logo {
		svg,
		img{
			width: 31rem;
			height: 22rem;
		}
		&:before,
		&:after{
			width: calc(100% + 10rem);
			height: calc(100% + 10rem);
		}
	}
	.header__btn{
		width: 50%;
		min-width: 17.8rem;
	}
}
@media screen and (min-width:$point-3) {
	.header_fixed,
	.header_style-bg{
		padding: 1.5rem 0;
		&:before{
			opacity:1;
			visibility: visible;
		}
		.btn{
			border: .3rem solid #fff;
			background: none;
			line-height: 5.8rem;
		}
		.nav-link{
			&:hover,
			&:focus,
			&._active{
				background-color: #fff;
				color: $main-color-dark;
			}

		}
	}

}
@media screen and (max-width:$point-3) {
	.header{
		@include transition(none);
		height: 8.6rem;
		position: fixed;
		padding-bottom: 0;
		&.animation:not(.no-animation){
			animation-duration: 2s;
		}
	}
	.header-wrap{
		&.nav-up{
			pointer-events: none;
		}
		&.nav-down{
			position: absolute;
			top: 0;
			width: 100%;
			pointer-events: auto;
			.header__center-holder{
				@include transform(translateY(0));
				&:after{
					opacity:1;
					visibility: visible;
				}
			}
		}
	}
	.header__center-holder{
		@include transition;
		@include transform(translateY(calc(-100% - 2.2rem)));
		height: 6.4rem;
		&:after{
			@include transition;
			opacity:0;
			visibility: hidden;
			content:'';
			position: absolute;
			height: 100%;
			bottom: 0;
			left: 2rem;
			right: 2rem;
			box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.5);
		}
	}
	.logo {
		svg{
			width: 24.6rem;
			height: 17.4rem;
		}
		&:before,
		&:after{

		}
	}

	.nav-wrap{
		&:before{
			left: 0;
			max-width: 100%;
		}
	}
	.navbar-modal{
		top: 6.4rem;
	}
}