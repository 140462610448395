.not-ie,
.not-ie *{
	cursor: url(../i/cursors/cursor-default.cur), auto;
}
@media screen and (max-width:$point-2) {
	.not-ie{
		* {
			cursor: auto;
		}
	}
}