.is_stuck:not(.mobile-visible){
	@include transition(all, 0.2s, ease-in);
	position: fixed;
	top: 9.3rem;
	opacity: 0;
	visibility: hidden;
	&:before,
	&:after{
		opacity:0;
		visibility: hidden;
	}
	&._visible{
		opacity: 1;
		visibility: visible;
	}
}
@media screen and (max-width:$point-3) {
	.is_stuck{
		@include transition(all, 0.2s, ease-in);
		@include transform(translateY(-40px));
		position: fixed;
		background-color: #fff;
		font-weight: 900;
		font-style: normal;
		text-align: center;
		padding: 1.6rem 1rem 1.1rem;
		letter-spacing: 0;
		font-size: 1em;
		color: $link-color;
		z-index: 4;
		width: calc(100% - 4rem);
		top: 7.9rem;
		opacity: 0;
		visibility: hidden;
		&:before,
		&:after{
			opacity:0;
			visibility: hidden;
		}
		&._visible{
			@include transform(translateY(0));
			opacity: 1;
			visibility: visible;
		}
		&.nav-up{
			@include transform(translateY(-64px));
		}
	}
}