.s-intro{
	padding: 11.2rem 0 0;
	position: relative;
	height: 100vh;
	min-height: 72.3rem;
	.swiper-container{
		height: calc(100% - 1rem);
		margin-top: -4rem;
		padding-top: 4rem;
	}
}
.swiper-container{
	.s-intro__contact{
		top: calc(50% + 2rem)
	}
}
.slideInUp{
	@include transition;
	position: relative;
	z-index: 2;
	width: 100%;
	&.animation{
		animation-name: fadeInUp;
		animation-duration: 1.5s;
	}
}
.s-intro__img{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background-position: center center;
	&:after{
		content:'';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(#000, 0.4);
	}
	&._no-overlay,
	&._empty{
		&:after{display: none;}
	}
}
.s-intro__center-holder{
	@include flex_block(row, nowrap, flex-end, center, center);
	height: 100%;
	width: 100%;
	position: relative;
}
.swiper-slide{
	.s-intro__center-holder{
		margin: 0;
	}
}
.s-intro__contact{
	-webkit-transform: translate3d(0, -50%, 0);
	transform: translate3d(0, -50%, 0);
	width: 36.9rem;
	position: absolute;
	top: 50%;
	left: calc((100% - 96rem)/2);
	z-index: 2;
	&.animation{
		animation-name: introContactAnimation;
		animation-duration: 1.5s;
	}
	&._style-black{
		color: $color-text-base;
		.s-intro__address{
			color: $color-text-base;
		}
	}
	&._static{
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		position: static;
		&.animation{
			animation-name: introStaticContactAnimation;
			animation-duration: 1.5s;
		}
	}
	&._top-right{
		max-width: 38.8rem;
		padding-top: 3.2rem;
		width: auto;
		&.animation{
			animation-name: fadeInRightContact;
			animation-duration: 4s;
		}
	}
}
.social-wrap{
	& + .s-intro__address-wrap{
		padding-top: 4.7rem;
	}
}
.s-intro__address-wrap{
	.icon{
		margin-bottom: 1.2rem;
	}
	& + .social-wrap{
		padding-top: 3.3rem;
	}
}
.s-intro__address{
	font-size: em(14);
	margin-top: -.6rem;
}

// intro gallery column
.swiper-wrapper{
	&.animation{
		animation-name: introGalleryAnimation;
		animation-duration: 1.5s;
		& + .swiper-pagination{
			animation-name: paginationAnimation;
			animation-duration: 1.5s;
		}
	}
	.s-intro__gallery-col{
		&:before{display: none;}
	}
}
.gallery-col-wrap{
	@include flex_block(column, nowrap, center, flex-end, center);
	height: 100%;
	position: relative;
	z-index: 1;
	width: 50%;
	.s-intro__gallery-col{
		width: calc(100% - 7rem);
	}
	&.animation{
		animation-name: introGalleryAnimation;
		animation-duration: 1.5s;
	}
	&:before{
		content:'';
		position: absolute;
		top: 0;
		bottom: 5rem;
		left: 0;
		width: .1rem;
		background-color: #fff;
		opacity: 0.4;
	}
}
.s-intro__gallery-col{
	text-align: left;
	width: calc(50% - 7rem);
	position: relative;
	z-index: 1;
	&.animation{
		animation-name: introGalleryAnimation;
		animation-duration: 1.5s;
	}
}

// gallery first section
.main-event{
	padding: 0 0 4rem 7rem;
	font-weight: 800;
	letter-spacing: -.1rem;
}
.main-event .link-white {
	max-width: 100%;

	svg {
		display: block;
		max-width: 100%;
		height: auto;
	}
}
.main-event__date{
	margin-bottom: 3.1rem;
	margin-left: -7rem;
}
.scroll-down{
	@include flex_block(row, nowrap, flex-start, flex-start, flex-start);
	color: $link-hover-color;
	font-weight: 600;
	padding-top: 2.7rem;
	& + .icon-moscow-city{
		color: $link-hover-color;
		margin-top: -6.3rem;
	}
}
.scroll-down__text{
	font-size: em(18);
}
.icon-mouse-next{
	margin-right: 1.2rem;
}
.prize{
	.scroll-down{
		padding-top: 6.3rem;
	}
}
.prize-wrap{
	margin-left: -.6rem;
	width: 96%;
	&.ff{
		.prize__value{
			font-size: em(107);
		}
	}
}
.prize__value{
	margin-bottom: 0;
	display: inline-block;
	vertical-align: top;
	letter-spacing: -.2rem;
	font: italic 900 110px/1em $main-font;
	stroke-width: 4px;
	fill: none;
	width: 100%;
	stroke: $main-color-light;
	margin-bottom: -.7rem;
}
.prize__currency{
	font-size: em(30);
	line-height: em(26,30);
	text-align: right;
	font-weight: 900;
	font-style: italic;
	margin: -1rem 0 1.6rem 0;
	display: block;
	text-align: right;
	color: $main-color-light;
}
.prize__note{
	font-size: em(24);
	line-height: em(32,24);
	font-weight: 900;
	padding-top: 1.2rem;
	margin-bottom: 0;
}
.s-intro{
	.registration-steps{
		li{
			margin-bottom: .8rem;
		}
	}
}
.registration-steps{
	@include reset-ul;
	padding-bottom: 3.8rem;
	width: 100%;
	margin-left: -1rem;
	li{
		@include flex_block(row, nowrap, flex-start, flex-start, flex-start);
		margin-bottom: 2rem;
		position: relative;
		&:last-child{
			margin-bottom: 0;
		}
	}
}
.registration-steps__number{
	font: italic 900 49px/1em $main-font;
	stroke-width: 2px;
	fill: none;
	stroke: $main-color-light;
	margin: -.8rem 1.7rem 0 0;
	min-width: 42px;
}
.s-intro__address-wrap {
	display: flex;
	align-items: center;
}
.social-wrap + .s-intro__address-wrap a {
	flex: 0 0 100px;
	max-width: 100px;
	margin-right: 15px;

	img {
		display: block;
		max-width: 100%;
	}
}
.s-intro__address {
	margin: 0;
	flex-basis: 0;
	flex-grow: 1;
	max-width: 100%;
}
@media screen and (max-width:$point-2) {
	.s-intro__contact{
		left: 2rem;
	}
	.s-intro__gallery-col{
		@include flex_block(row, wrap, space-between, flex-start, flex-start);
		width: calc(50% - 4rem);
		.barber{
			width: 47%;
			padding-right: 1.5rem;
			&:first-of-type{
				width: 53%;
			}
		}
	}
	.gallery-col-wrap{
		.s-intro__gallery-col{
			width: calc(100% - 4rem);
		}
	}
	.prize__value{
		stroke-width: 4px;
	}
	.prize__currency{
		font-size: em(24);
	}
	.icon-moscow-city{
		max-width: 100%;
	}
	.main-event{
		padding-left: 3rem;
	}
	.main-event__date{
		margin-left: -3rem;
	}
}
@media screen and (max-width:$point-3), screen and (max-height:375px) {
	.s-intro{
		padding: 10.4rem 0 7.4rem;
		height: auto;
		&._home{
			height: auto;
			min-height: 1px;
		}
		.swiper-container,
		.swiper-wrapper,
		.swiper-slide{
			height: auto;
			display: block;
			overflow: visible;
		}
		.swiper-slide{
			padding-bottom: 5rem;
			overflow: visible;
			&:last-child{
				padding-bottom: 0;
			}
		}
	}
	.logo{
		margin: 0 0 3rem;
	}
	.social-wrap + .s-intro__address-wrap {
		padding-top: 4.1rem;
	}
	.s-intro__img{
		height: auto;
	}
	.s-intro__contact{
		@include transform(translateX(0) translateY(0))
		position: relative;
		top: auto;
		left: auto;
		width: 100%;
		padding: 0 2rem 7.7rem;
		&._static{
			padding-bottom: 1.3rem;
		}
		&._top-right{
			&.animation{
				animation-name: slideInLeftContactXS;
				animation-duration: 2.5s;
			}
		}
	}
	.s-intro__center-holder{
		padding: 0 2rem;
		height: auto;
		display: block;
		overflow: visible;
	}
	.s-intro__gallery-col{
		width: 100%;
		padding: 0;
		.barber{
			margin-bottom: 0;
		}
	}
	.s-intro__center-holder{
		& > .s-intro__contact{
			padding-right: 0;
			padding-left: 0;
		}
	}
	.gallery-col-wrap{
		width: 100%;
		&:before{display: none;}
		.s-intro__gallery-col{
			width: 100%;
		}
	}
	.main-event{
		padding: 0;
	}
	.main-event__date{
		margin-left: 0;
	}
	.prize-wrap{
		width: 30rem;
		min-width: 28rem;
	}
	.prize__value{
		height: 7.6rem;
	}
	.prize__currency{
		letter-spacing: 0;
		margin: 2px 0 .6rem;
	}
	.prize .scroll-down{
		padding-top: 5.3rem;
	}
	.scroll-down + .icon-moscow-city{
		margin-top: -5.1rem;
	}
}
