.content{
	min-height: calc(100vh - 454px);
}
section{
	padding: 8.5rem 0;
	&:not(.s-intro) {
		position: relative;
		z-index: 1;
	}
	&:focus{
		outline: none;
	}
}
.bg-white{
	padding-bottom: 8.5rem !important;
	&.pb-0{
		padding-bottom: 0 !important;
	}
	& + .bg-white{
		padding-top: 0 !important;
	}
}
img[object-fit=cover] {
	margin-bottom: 30px;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.custom-object-fit{
	height: 100%;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	img{
		display: none;
	}
}
.w-100{
	width: 100%;
}
.m-0{
	margin: 0 !important;
}
.pb-0{
	padding-bottom: 0 !important;
}
.mb-0{
	margin-bottom: 0 !important;
}
.mb-lg{
	margin-bottom: 6.4rem;
}
.mb-sm{
	margin-bottom: 2rem;
}
.mb-md{
	margin-bottom: 4rem !important;
}
.ml-0{
	margin-left: 0 !important;
}
.pb-md{
	padding-bottom: 4rem;
}
.pt-md{
	padding-top: 4rem;
}
.pt-sm{
	padding-top: 2rem;
}
.pl-lg{
	padding-left: 9rem;
}
.text-md{
	font-size: em(26);
	line-height: em(30,26);
}
.text-sm{
	font-size: 1.6rem;
	line-height: em(24,16);
}
.text-half-sm {
	font-size: 1.4rem;
}
.text-xs{
	font-size: 1.2rem;
}
.c-link{
	color: $link-color;
}
.c-danger{
	color: $color-danger;
}
.c-link-hover{
	color: $link-hover-color;
}
.c-base{
	color: $color-text-base;
}
.c-main-dark{
	color: $main-color-dark;
}
.c-white,
a.link-white{
	color: $color-white;
}
a.link-white{
	border-color: #fff;
	&:hover {
		color: $link-hover-color;
	}
}
a.link-base{
	color: $color-text-base;
	&:hover {
		color: $main-color-dark;
	}
}
a.link-main-light{
	color: $main-color-light;
	&:hover {
		color: $link-hover-color;
	}
}
a.link-main-dark{
	color: $main-color-dark;
	&:hover {
		color: $link-hover-color;
	}
}
.text-shadow{
	position: relative;
	&:before,
	&:after{
		@include transition;
		content: attr(data-text) + ' ';
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		height: 100%;
		-webkit-filter: blur(20px);
		filter: blur(20px);
		-webkit-background-clip: text;
		background-clip: text;
		-webkit-text-fill-color: transparent;
		background-image: linear-gradient(to left, $main-color-light, $main-color-dark);
	}
	span{
		color: #fff;
		position: relative;
		z-index: 3;
	}
}
.font-weight-light{
	font-weight: 300;
}
.font-weight-heavy{
	font-weight: 800;
}
.font-weight-black{
	font-weight: 900;
}
.font-style-italic{
	font-style: italic;
}
.flex-center{
	@include flex_block(row, wrap, center, center, center);
}
.text-center {
	text-align: center;
}
.hidden{display: none;}
.tablet-visible{
	display: none !important;
}
address{
	font-style: normal;
}
.d-ib{
	display: inline-block;
	vertical-align: top;
}
.d-b{
	display: block;
}
.justify-space-between{
	justify-content: space-between !important;
}
.h-a{
	height: auto !important;
	min-height: 1px !important;
}
.content-list{
	@include reset-ul;
	margin: 0 0 1.6rem;
	li{
		position: relative;
		padding: 0 0 1.2rem 2.7rem;
		&:before{
			@include border-radius(50%);
			content:'';
			position: absolute;
			top: .5rem;
			left: .2rem;
			background-image: linear-gradient(to bottom, $main-color-dark, $main-color-light);
			width: 1.3rem;
			height: 1.3rem;
		}
		&:last-child{
			padding-bottom: 0;
		}
	}
	& + p.pl-ul{
		padding-left: 2.7rem;
	}
}
.btn-wrap{
	white-space: normal;
	line-height: 1.2em;
}
.td-n{
	text-decoration: none;
}
.test-svg{
	position: absolute;
	z-index: 1;
	top: 15rem;
	left: 5rem;
}
@media screen and (min-width:1024px) {
	.tel-link {
		pointer-events: none;
		text-decoration: none;
		color: #fff;
	}
}
@media screen and (max-width:$point-2) {
	.desctop-visible{display: none !important;}
	.tablet-visible{
		display: block !important;
	}
	.tablet-hidden{
		display: none !important;
	}
}
@media screen and (min-width:768px) {
	.mobile-visible {
		display: none !important;
	}
}
@media screen and (min-width:$point-3) {
	.text-sm-md{
		font-size: em(26);
		line-height: em(30,26);
	}
	.text-sm-center{
		text-align: center;
	}
	.justify-sm-center{
		justify-content: center !important;
	}
	.pl-sm-lg{
		padding-left: 9rem !important;
	}
}
@media screen and (max-width:$point-3) {
	.mobile-hidden {
		display: none !important;
	}
	section{
		padding: 8rem 0;
	}
	.lead{
		line-height: em(32,20);
	}
	.text-sm-right{
		text-align: right;
	}
	.text-sm-center{
		text-align: center;
	}
	.bg-white{
		padding-bottom: 8rem !important;
		& + .bg-white{
			padding-top: 0 !important;
		}
	}
}