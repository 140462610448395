.video-item {
	overflow: hidden;
	position: relative;
	z-index: 1;
	width: 100%;
	background-image: radial-gradient(circle at 50% 48%, #000000, #000000 55%, $main-color-light);
	padding: 3rem 5.4rem;
}
.video-item__img{
	position: relative;
	opacity:0.6;
	&:after{
		content:'';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(#000,0.4);
	}
}
.video-item__link {
	@include flex_block(row, nowrap, center, center, center);
	@include transform(translateX(-50%) translateY(-50%));
	@include box-shadow(0 1rem 3.3rem $main-color-dark);
	@include border-radius(50%);
	z-index: 1;
	top: 50%;
	left: 50%;
	border: 0;
	position: absolute;
	width: 11.9rem;
	height: 11.9rem;
	background-image: linear-gradient(to right, $main-color-light, $main-color-dark);
	svg{
		@include transition;
		width: 4rem;
		height: 6.7rem;
		margin-right: -1.8rem;
		color: #fff;
	}
}
.embed-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;
	display: none;
	iframe,
	object,
	embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

@media screen and (max-width:$point-3) {
	.video-item{
		margin: 0 -2rem;
		width: auto;
		padding: 1.2rem 2rem;
	}
	.video-item__link{
		@include box-shadow(0 7px 13px 0 #df3733);
		width: 8rem;
		height: 8rem;
		svg{
			width: 2.7rem;
			height: 4.5rem;
			margin-right: -1.1rem;
		}
	}
}
