label {
	display: inline-block;
	vertical-align: top;
	margin-bottom: 0.9rem;
	svg.icon,
	span.text {
		display: inline-block;
		vertical-align: middle;
	}
}
form{
	@include flex_block(row, wrap, flex-start, flex-start, flex-start);
	margin: 0 -1rem;
}
.input-group{
	position: relative;
	margin: 0 1rem 1.8rem;
	width: calc(100% - 2rem);
	&:last-of-type{
		margin-bottom: 0;
	}
	&._half-width{
		width: calc(50% - 2rem);
	}
	&._lg{
		width: calc(100% - 20rem);
	}
	&._md{
		width: calc((100% - 22rem)/2);
	}
	&._sm{
		width: 16rem;
	}
}
.form-control{
	height: 5.8rem;
	border: 3px solid $main-color-dark;
	display: block;
	color: $main-color-dark;
	background: none;
	width: 100%;
	font: em(18) $main-font;
	padding: 0 1.6rem;
	background-color: $color-white;
	&.error,
	&:focus{
		& + .label-area{
			background-color: #fff;
			color: $main-color-dark;
			font-size: em(14);
			line-height: em(18,14);
			top: -.7rem;
			left: 1.4rem;
			padding: 0 12px 0 5px;
			pointer-events: auto;
			&._grey{
				background-color: #e3e3e3;
				color: $main-color-dark;
				&:before{
					background-color: #e3e3e3;
				}
			}
			&:before{
				content:'';
				position: absolute;
				top: .7rem;
				background-color: #fff;
				height: .3rem;
				right: 0;
				left: 0;
			}
			label{
				position: relative;
			}
		}
	}
	&.error{
		border-color: #d0021b;
		& + .label-area{
			color: #d0021b;
		}
	}
}
.label-area{
	@include transition;
	color: rgba($main-color-dark, 0.6);
	position: absolute;
	top: 1.9rem;
	left: 1.6rem;
	font: em(18) $main-font;
	pointer-events: none;
	&.show{
		color: $main-color-dark;
		font-size: em(14);
		line-height: em(18,14);
		top: -.7rem;
		left: 1.4rem;
		padding: 0 12px 0 5px;
		pointer-events: auto;
		&._grey{
			&:before{
				background-color: #e3e3e3;
			}
		}
		&:before{
			content:'';
			position: absolute;
			top: .7rem;
			background-color: #fff;
			height: .3rem;
			right: 0;
			left: 0;
		}
		label{
			position: relative;
		}
	}
}
.error-area{
	padding-top: 2px;
}
label.error{
	color: #d0021b;
	font-size: em(14);
}
textarea{
	&.form-control{
		padding-top: 1.7rem;
		height: 11.6rem;
		min-height: 11.6rem;
		max-height: 11.6rem;
		resize: none;
	}
}
.ticket-counter-wrap{
	@include flex_block(row, nowrap, flex-start, center, flex-start);
	width: 100%;
	padding: 0 1rem 4rem;
}
.ticket-counter-wrap__text{
	font-size: em(18);
	margin-right: 2.6rem;
	color: $main-color-dark;
}
.ticket-counter{
	@include flex_block(row, nowrap, flex-start, center, flex-start);
}
.ticket-counter__minus,
.ticket-counter__plus{
	@include border-radius(50%);
	width: 4.4rem;
	height: 4.4rem;
	border: .3rem solid $main-color-dark;
	background: none;
	position: relative;
	margin: 0 .8rem;
	&:after,
	&:before{
		@include transition;
		@include transform(translateX(-50%) translateY(-50%));
		content:'';
		position: absolute;
		top: 50%;
		left: 50%;
		background-color: $main-color-dark;
	}
	&:after{
		width: 1.6rem;
		height: .5rem;
	}
	&:before{
		width: .5rem;
		height: 1.6rem;
	}
	&:hover{
		background-color: $main-color-dark;
		&:before,
		&:after{
			background-color: #fff;
		}
	}
}
.ticket-counter__minus{
	&:before{display: none;}
}
.ticket-counter__input{
	width: 14.4rem;
	padding: 0 .2rem;
	font-size: em(18);
	font-weight: 900;
	text-align: center;
	margin: 0 .8rem;
}
.radio-group{
	padding-bottom: 1.4rem;
}
/* custom radio and checkbox */
[type="radio"],
[type="checkbox"] {
	position: absolute;
	left: -9999px;
	&:checked,
	&:not(:checked) {
		position: absolute;
		left: -9999px;
		& + label{
			position: relative;
			display: inline-block;
			font-size: em(18);
			color: $main-color-dark;
			padding: .2rem 0 .6rem 4.9rem;
			&:before {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 2.4rem;
				height: 2.4rem;
				border: .5rem solid $main-color-dark;
				background: #fff;
			}
			&:after {
				@include transition;
				position: absolute;
			}
		}
	}
	&:not(:checked){
		& + label{
			&:after {
				opacity: 0;
				-webkit-transform: scale(0);
				transform: scale(0);
			}
		}
	}
	&:checked{
		& + label{
			&:after {
				opacity: 1;
				-webkit-transform: scale(1);
				transform: scale(1);
			}
		}
	}
	&:focus{
		& + label{
			&:before{
				border-color: $link-color;
			}
		}
	}
}
[type="radio"]{
	&:checked,
	&:not(:checked) {
		& + label{
			&:before {
				@include border-radius(50%);
			}
			&:after{
				@include border-radius(50%);
				content: '';
				width: 1.8rem;
				height: 1.8rem;
				background-image: linear-gradient(to bottom, $main-color-dark, $main-color-light);
				top: .8rem;
				left: .8rem;
			}
		}
	}
}
[type="checkbox"]{
	&:checked,
	&:not(:checked) {
		& + label{
			&:after{
				@include arrow(bottom,$link-color,.9rem,.2rem);
				height: 1.6rem;
				content: '';
				top: -.5rem;
				left: .9rem;
			}
		}
	}
}
@media screen and (max-width:$point-3) {
	.input-group._half-width{
		width: 100%;
		margin-bottom: 1.8rem;
	}
	.ticket-counter-wrap{
		flex-wrap: wrap;
		padding: 1.6rem 1rem 6rem;
	}
	.ticket-counter-wrap__text{
		width: 100%;
		color: $main-color-dark;
		margin: 0 0 1.6rem;
	}
	.ticket-counter{
		margin: 0 auto;
	}
	.input-group{
		&._half-width,
		&._lg,
		&._md,
		&._sm{
			width: 100%;
		}
	}
}