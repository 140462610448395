.s-contact{
	height: 70rem;
	&._inner{
		height: auto;
		min-height: 4.5rem;
		background-color: #1a0603;
		color: #fff;
		padding-top: 0;
		overflow: hidden;
		.map-area{
			position: static;
		}
	}
}
.s-contact__head{
	position: relative;
	z-index: 1;
}
.contact-block{
	@include flex_block(row, wrap, flex-start, flex-start, flex-start);
	@include box-shadow(0 10px 20px 0 $color-warning);
	max-width: 48.9rem;
	background-color: $link-color;
	padding: 3.5rem 2.2rem 2rem;
	line-height: em(32,20);
	position: absolute;
	left: calc((100% - 96rem)/2);
	z-index: 1;
	font-size: em(16);
	bottom: 9rem;
	.icon-print-house-logo{
		min-width: 13.4rem;
		height: 11.9rem;
	}
}
.address-wrap{
	@include flex_block(row, nowrap, flex-start, center, flex-start);
	font-size: em(20);
	line-height: em(39,24);
	font-weight: 800;
	address{
		width: calc(100% - 6.4rem);
		padding-left: 4rem;
		font-style: normal;
	}
	&._xs{
		padding-bottom: 0;
		address{
			font-size: em(14,18);
			line-height: em(23,14);
			font-weight: 400;
			padding-left: 2.2rem;
		}
		a {
			flex: 0 0 60px;
			max-width: 60px;
		}
	}
	a {
		flex: 0 0 100px;
		max-width: 100px;

		img {
			display: block;
			max-width: 100%;
		}
	}
}
.map-area{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	&._short{
		height: 45rem;
	}
	#map{
		height: 100%;
	}
	* {
		cursor: url(../i/cursors/cursor-default.cur), auto !important;
	}
	& + .center-holder{
		padding-top: 8.5rem;
	}
}
@media screen and (max-width:$point-2) {
	.contact-block{
		left: 2rem;
	}
}
@media screen and (max-width:$point-3) {
	.s-contact{
		height: auto;
		min-height: 70rem;
		background-color: #e8e8e8;
		padding-right: 2rem;
		padding-left: 2rem;
		padding-bottom: 0;
		&._inner{
			padding-right: 0;
			padding-left: 0;
			padding-bottom: 5rem;
		}
	}
	h2.center-holder{
		margin-bottom: 4.1rem;
	}
	.s-contact__head{
		padding: 0;
	}
	.map-area{
		position: static;
		width: 100%;
		height: 40rem;
	}
	.contact-block{
		max-width: 100%;
		padding: 2.7rem 2.2rem 4.2rem;
		position: relative;
		top: auto;
		left: auto;
		bottom: auto;
		.icon-print-house-logo {
			min-width: 10rem;
			max-width: 10rem;
		}
	}
	.map-area{
		margin: 0 -2rem;
		width: auto;
	}
	.contact-block__address{
		font-size: 1em;

	}
	.contact-block__subway-title{
		font-size: 1em;
	}
	.address-wrap{
		font-size: em(20);
		address{
			width: calc(100% - 10rem);
			padding-left: 2.7rem;
		}
		&._xs{
			flex-wrap: wrap;
			address{
				font-size: em(20);
				line-height: em(32,20);
				font-weight: 300;
				width: 100%;
				padding: .8rem 0 0;
			}
		}
	}
}
@media screen and (max-width:$point-4) {
	.address-wrap{
		flex-wrap: wrap;
		address{
			width: 100%;
			padding: 2rem 0 0;
		}
	}
}

.s-intro__address-wrap {
	.link-base {
		flex: 0 0 100px;
		max-width: 100px;
		margin-right: 15px;

		img {
			display: block;
			width: 100%;
		}
	}
}