.s-partners{
	background-color: $body-bg;
}
.organizers-wrap{
	padding-top: 4.8rem;
}
.organizers{
	@include flex_block(row, wrap, flex-start, flex-start, flex-start);
	margin: 0 -1rem -4rem;
}
.organizer-item{
	@include flex_block(row, wrap, center, center, center);
	height: 30.1rem;
	width: calc(25% - 2rem);
	margin: 0 1rem;
	border-style: solid;
	border-width: 1px;
	border-image-source: linear-gradient(to top, $main-color-light, $color-warning);
	border-image-slice: 1;
	padding: 2rem;
	margin-bottom: 4rem;
}
.organizer-item__logo{
	@include flex_block(row, nowrap, center, center, center);
	height: 12.5rem;
	width: 100%;
	color: #fff;
	margin-bottom: 4rem;

	img {
		display: block;
		max-width: 95%;
		max-height: 95%;
	}
	.icon-logo-el{
		width: 12rem;
		height: 6.3rem;
	}
	.icon-logo-lock-stock-barrel{
		width: 12.6rem;
		height: 12.6rem;
	}
	.icon-logo-4system{
		width: 11rem;
		height: 11.8rem;
	}
	.icon-logo-feather{
		width: 16.9rem;
		height: 4.5rem;
	}
	.icon-logo-reuzel{
		width: 11.6rem;
		height: 12.6rem;
	}
	.icon-logo-simushka{
		width: 14rem;
		height: 1.5rem;
	}
}
.organizer-item__name{
	text-align: center;
}
@media screen and (max-width:$point-2) {

}
@media screen and (max-width:$point-3) {
	.organizer-item{
		width: calc(50% - 1.2rem);
		margin: 0 .6rem 2rem;
		height: 21.7rem;
	}
	.organizers{
		margin: 0 -.6rem;
	}
	.organizer-item__logo{
		height: 10rem;
		margin-bottom: 1.5rem;
		svg{
			max-height: 100%;
		}
	}
}