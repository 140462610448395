#loader {
	position: fixed;
	z-index: 1002;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: #000;
}
.loader__img {
	width: 200px;
	height: 143px;
	position: absolute;
	left: 50%;
	top: 50%;
	margin: -71px 0 0 -100px;
	background: url(../i/logo/logo.svg);
	-webkit-background-size: contain;
	background-size: contain;
	background-repeat: no-repeat;
	z-index: 1;
}
.loader__img_hover{
	width: 200px;
	height: 143px;
	position: absolute;
	left: 50%;
	top: 50%;
	margin: -71px 0 0 -100px;
	z-index: 999;
	animation-name: loaderAnimationImgHover;
	animation-duration: 3s;
	overflow: hidden;
	&:before{
		content:'';
		position: absolute;
		top: 0;
		left: 0;
		width: 200px;
		height: 143px;
		background: url(../i/logo/logo_color.svg);
		-webkit-background-size: contain;
		background-size: contain;
		background-repeat: no-repeat;
	}
}