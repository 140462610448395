#wrapper {
	overflow: hidden;
	position: relative;
	width: 100%;
}
.center-holder {
	max-width: 102rem;
	margin: 0 auto;
	padding: 0 3rem;
}
.body_nav-open{
	overflow: visible !important;
	padding-right: 0 !important;
	.header{
		padding-right: 0 !important;
	}
}
@media screen and (max-width:$point-2) {
	.center-holder{
		padding-right: 2rem;
		padding-left: 2rem;
	}
}
@media screen and (max-width:$point-3) {
	.body_nav-open{
		overflow: hidden !important;
		height: 100%;
	}
}