@function em($pixels, $context: $browser-context) {
	@return #{$pixels/$context}em;
}
//---------------------------------------------fonts

$browser-context: 20;

$font-path: "../fonts/";
$main-font: 'helveticaneuecyr',sans-serif;
$heading-font: 'helveticaneuecyr',sans-serif;

$font-size-base: 2em;
$line-height-base: 1.45;

//---------------------------------------------colors

$color-text-base: #363636;
$main-color-dark: #d02703;
$main-color-light: #ef5f41;

$color-white: #fff;
$color-turquoise: #93e1d8;
$color-turquoise-dark: #357f76;
$color-turquoise-light: #94e1d9;
$color-danger: #df3733;
$color-warning: #ef5f41;

$body-bg: #e8e8e8;
$link-color: $main-color-light;
$link-border-color: opacify(rgba($link-color, 0), 1);
$link-hover-color: $color-warning;
$link-hover-border-color: opacify(rgba($link-hover-color, 0), 1);
$link-active-color: $color-warning;
$link-active-border-color: opacify(rgba($link-active-color, 0), 0.4);

//---------------------------------------------media points

$point-2: 1023px;
$point-3: 767px;
$point-4: 374px;