.s-purchase{
	background-color: $color-white;
	padding-bottom: 8.8rem;
}
.tickets-tabset{
	@include reset-ul;
	@include flex_block(row, nowrap, flex-start, stretch, flex-start);
	text-align: center;
	margin-bottom: 2rem;
}
.tickets-tabset__item{
	width: 50%;
	font-size: em(24);
	font-weight: 900;
	height: 7rem;
	&.active{
		background-color: $main-color-dark;
		.tickets-tabset__link{
			color: #fff;
		}
	}
}
.tickets-tabset__price{
	text-transform: uppercase;
	display: block;
	margin: -.2rem 0 0;
	font-weight: 600;
}
.tickets-tabset__link{
	border: none;
	height: 100%;
	padding: .7rem 0 .8rem;
	border: .3rem solid $main-color-dark;
	display: block;
	color: $main-color-dark;
	text-decoration: none;
	&:hover,
	&:focus{
		color: $main-color-dark;
		background-color: rgba($main-color-dark, 0.2);
		border-color: $main-color-dark;
	}
	&._disable{
		background-color: #e8e8e8;
		pointer-events: none;
		border-color: #e8e8e8;
		color: #c7c7c7;
	}
}
.tab-pane{
	display: none;
	padding-top: 4rem;
	&.active{display: block;}
}
.ticket-items{
	@include flex_block(row, nowrap, flex-start, stretch, flex-start);
	margin: 0 -1rem;
}
.ticket-item{
	width: 40.7rem;
	max-width: 50%;
	padding: 0 1rem;
	text-align: center;
}
.ticket-item_selected{
	.ticket-item__link{
		background-color: $link-color;
		border-color: $link-color;
		color: #fff;
		.ticket-item__date{
			color: #fff;
		}
	}
}
.ticket-item__link{
	background-color: $main-color-light;
	color: $color-text-base;
	display: block;
	border: .3rem solid $main-color-light;
	padding: 4.9rem 0 0;
	position: relative;
	&:before{
		@include transition;
		content:'';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 50%;
		background-image: linear-gradient(to bottom, $main-color-dark, $main-color-light);
		opacity: 0;
		visibility: hidden;
	}
	&:hover,
	&:focus{
		@include box-shadow(0 10px 20px 0 $link-hover-color);
		color: $color-text-base;
		background-color: $main-color-light;
		border-color: $link-color;
		&:before{
			opacity: 1;
			visibility: visible;
		}
		.ticket-item__purchase-info{
			&:before{
				opacity: 1;
				visibility: visible;
			}
		}
	}
}
.ticket-item__title{
	@include flex_block(row, wrap, center, flex-end, center);
	font-weight: 600;
	padding: 0 2.5rem;
	line-height: em(20);
	margin: 0 0 2rem;
	position: relative;
	z-index: 1;
}
.ticket-item__description{
	padding-right: 1.5rem;
	padding-left: 1.5rem;
	padding-bottom: 3.4rem;
	font-size: 1em;
	line-height: 1.2;
	position: relative;
	z-index: 1;
}
.ticket-item__date{
	@include transition;
	color: $color-text-base;
	display: block;
	padding-bottom: 5rem;
}
.ticket-item__purchase-info{
	@include transition;
	@include flex_block(row, wrap, center, center, center);
	background: #fff;
	background:linear-gradient(-225deg, transparent 15px, #fff 0);
	height: 17.5rem;
	padding: 0 1rem;
	position: relative;
	&:before{
		@include transition;
		content:'';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: $main-color-light;
		opacity: 0;
		visibility: hidden;
	}
	p{
		margin: -.6rem 0 0;
		position: relative;
		z-index: 1;
	}
}
.ticket-price{
	font-size: em(48);
	display: block;
	margin: -1.6rem 0 -1.8rem;
}
.purchase-form{
	max-width: 81.6rem;
}
.s-purchase__h3{
	font-size: em(30);
}
.tickets-tab-content{
	background: url(../i/buy-ticket/form-gradient.png);
	padding: 0 2rem 8rem;
}
.ticket-info__col{
	padding-right: 3rem;
}
.ticket-info{
	@include flex_block(row, nowrap, space-between, stretch, flex-start);
}
.ticket-info__price-wrap{
	@include flex_block(row, wrap, center, center, center);
	flex: 0 0 30rem;
	text-align: center;
}
.ticket-step{
	padding-bottom: 5.6rem;
	padding-top: 2.7rem;
	&:last-of-type{
		padding-bottom: 0;
	}
}
@media screen and (max-width:$point-2) {
	.ticket-item__link{
		font-size: em(18);
	}
	.ticket-item__date{
		padding-bottom: 3rem;
	}
}
@media screen and (max-width:$point-3) {
	.tickets-tabset{
		flex-wrap: wrap;
	}
	.s-purchase__form-wrap{
		padding-top: 4rem;
	}
	.tickets-tabset__item{
		width: 100%;
		min-height: 7rem;
	}
	.ticket-info__price-wrap{
		flex: auto;
	}
	.ticket-items{
		flex-wrap: wrap;
		margin: 0 -.7rem -2rem;
	}
	.ticket-item{
		width: 50%;
		padding: 0 .7rem 2rem;
	}
	.ticket-item__link{
		font-size: em(16);
		padding-top: 3.1rem;
	}
	.ticket-item__title{
		line-height: em(20,16);
		margin: 0 0 .8rem;
	}
	.ticket-item__description{
		padding-right: .5rem;
		padding-left: .5rem;
	}
	.ticket-item__date{
		padding-bottom: 2.3rem;
	}
	.ticket-item__purchase-info{
		font-size: em(18,16);
		background-color: #fff;
		background: linear-gradient(-225deg, transparent 19px, #fff 0);
	}
	.ticket-item__main-price{
		font-size: em(40,18);
		margin: -1.2rem 0 -1.4rem;
	}
	.ticket-item__regular-price {
		padding-top: 1.4rem;
		line-height: em(14,12);
		.text-danger{
			display: block;
		}
	}
	.s-purchase._no-top-padding{
		margin-top: -2.5rem;
	}
	.s-purchase__h3{
		font-size: em(26);
		line-height: em(23,26);
		margin-bottom: 3rem;
	}
	.header_style-purchase{
		width: 100%;
		.menu-opener{
			width: 100%;
		}
	}
	.tickets-tab-content{
		margin: 0 -2rem;
	}
	.tab-pane{
		padding-top: 2rem;
	}
	.ticket-info{
		flex-wrap: wrap;
	}
	.ticket-info__col{
		padding-right: 0;
	}
}
@media screen and (max-width:$point-4) {
	.ticket-item{
		width: 100%;
		max-width: 100%;
	}
}