.footer {
	padding: 6.4rem 0 5.5rem;
	position: relative;
	z-index: 1;
	background-color: #1a0603;
	&:after{
		content:'';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #1a0603;
	}
}
.footer__center-holder{
	@include flex_block(row, wrap, space-between, flex-start, flex-start);
	position: relative;
	z-index: 1;
}
.footer__short-col{
	width: 25rem;
}
.copyright{
	display: block;
	font-size: 1rem;
	font-weight: 600;
	text-transform: uppercase;
	margin-bottom: .5rem;
	color: $main-color-dark;
}
.non-disclosure-agreement{
	font-size: 1rem;
	line-height: 1.7rem;
	font-weight: 300;
	padding-bottom: 3.3rem;
	p{
		margin-bottom: .7rem;
	}
}
.footer__wide-col{
	width: calc(100% - 32.3rem);
}
.footer__nav{
	font-size: em(16);
	margin: 9px 0 3.9rem -.4rem;
}
.footer__nav-list{
	justify-content: space-between;
}
.footer__contact-columns{
	@include flex_block(row, nowrap, space-between, flex-start, flex-start);
	padding-bottom: 2.2rem;
}
.footer__contact-col{
	&:first-child{
		font-size: 1.4rem;
		line-height: 2.3rem;
	}
	.footer-link{
		float: right;
		clear: both;
	}
}
.footer-link{
	white-space: nowrap;
	font-weight: 300;
}
.footer__address{
	font-style: normal;
	max-width: 35.7rem;
	padding: .3rem 0 0;
}
.footer__bottom-columns{
	@include flex_block(row, nowrap, space-between, flex-start, flex-start);
	width: calc(100% - 32.3rem);
}
.footer__bottom-col{
	margin-right: 2rem;
	&:last-child{
		margin-right: 0;
	}
}
.footer__heading{
	opacity: 0.4;
}
.partners{
	@include reset-ul;
	@include flex_block(row, nowrap, space-between, center, flex-start);
}
.partners__item{
	margin-right: 2.5rem;
	&:last-child{
		margin-right: 0;
	}
}
a.partners__link{
	border: 0;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover,
	&:focus{
		color: #fff;
		opacity: 0.7;
	}
	svg{
		display: block;
	}
	img {
		display: block;
		max-width: 140px;
		max-height: 60px;
	}
}
@media screen and (max-width:$point-2) and (min-width:$point-3) {
	.footer{
		padding: 8rem 0 3.8rem;
	}
	.footer__wide-col{
		width: calc(100% - 23rem);
	}
	.footer__nav{
		font-size: 1.5rem;
		margin-bottom: 4rem;
	}
	a.footer__nav-link{
		font-weight: 600;
		padding-left: 0;
		padding-right: 0;
	}
	.footer__short-col{
		width: 20rem;
	}
	.footer__bottom-columns{
		width: calc(100% - 23rem);
	}
	.partners__item{
		margin-right: 1rem;
	}
	.non-disclosure-agreement{
		padding-bottom: 2rem;
	}

}
@media screen and (max-width:$point-3) {
	.footer{
		padding: 8rem 0 3.8rem;
	}
	a.footer__logo{
		margin-bottom: 7.5rem;
		order:1;
	}
	.footer__wide-col{
		width: 100%;
		order:2;
	}
	.footer__nav{
		font-size: em(42);
		display: block;
		margin: 0 0 3.6rem;
	}
	.footer__nav-list{
		display: block;
	}
	.footer__nav-item{
		padding: 0;
		width: 100%;
		margin-bottom: 2.9rem;
		opacity:1;
		visibility: visible;
	}
	a.footer__nav-link{
		margin: 0 -2rem;
		padding: .5rem 2rem;
		display: block;
		font-size: 1em;
		line-height: em(38,42);
	}
	.footer__contact-columns{
		display: block;
		font-weight: 300;
		padding-bottom: 3.6rem;
	}
	.footer__contact-col{
		@include clearfix;
		width: 100%;
		line-height: em(32,20);
		padding-bottom: 3.5rem;
		&:last-child{
			padding-bottom: 0;
		}
		&:first-child{
			font-size: em(20);
			line-height: em(32,20);
		}
		.footer-link{
			float: left;
			clear: left;
		}
	}
	.footer-link{
		color: #fff;
		margin-bottom: .2rem;
		&.tel-link{
			border: none;
		}
	}
	.footer__bottom-columns{
		order: 3;
		display: block;
		width: 100%;
		padding: 0 0 3.4rem;
	}
	.footer__bottom-col{
		padding-bottom: 3.8rem;
		&:last-child{
			padding-bottom: 0;
		}
	}
	.partners{
		margin-top: 0;
		flex-wrap: wrap;
		justify-content: flex-start;
	}
	.partners__item_full-width-xs{
		width: 100%;
		margin-bottom: 3.3rem;
	}
	.footer__short-col{
		order:4;
		margin-top: 0;
		width: 100%;
	}
	.copyright{
		font-size: em(14);
		line-height: em(23,14);
	}
	.non-disclosure-agreement{
		font-size: em(14);
		line-height: em(23,14);
		padding-bottom: 2.9rem;
		p{
			margin-bottom: 1.1rem;
		}
	}
}

@media screen and (max-width:$point-4) {
	.footer__main-nav{
		.main-nav__list{
			font-size: em(36,18);
		}
	}
}