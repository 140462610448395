.s-schedule{
	background-color: #1a0603;
}
.s-schedule__head{
	margin-bottom: .7rem;
}
.s-shedule__inner-head{
	@include flex_block(row, wrap, flex-start, flex-start, flex-start);
}
.s-schedule__frame{
	padding: 0 0 6.6rem;

	& + .invitation-area{
		padding-top: 1.6rem;
	}
}
.s-schedule__block{
	@include flex_block(row, nowrap, space-between, flex-start, flex-start);
}

// schedule item
.schedule-item-wrap{
	@include flex_block(row, nowrap, flex-start, center, flex-start);
	margin-bottom: 3.6rem;
	border-style: solid;
	border-width: 4px;
	border-image-source: linear-gradient(to top, $main-color-dark, $main-color-light);
	border-image-slice: 1;
	position: relative;
	& + h2{
		margin-top: 3rem;
	}
	&:last-of-type{
		margin-bottom: 0;
	}
}
.schedule-item__head{
	@include flex_block(row, nowrap, flex-start, flex-start, flex-start);
	font-size: em(30);
	line-height: em(30,30);
	padding-right: 2rem;
}
.schedule-item__date{
	background-image: linear-gradient(to left, $main-color-light, $color-warning);
	color: #000;
	font-weight: 900;
	padding: 2.1rem 2.4rem 1.9rem;
}
.schedule-item__title{
	font-size: em(30,30);
	line-height: em(30,30);
	padding: 2rem 0 2rem 2.2rem;
}
.schedule-item__frame{
	padding: 2.2rem 2rem 4rem;
	position: relative;
	overflow: hidden;
	& + .schedule-item__frame{
		padding-top: 3.3rem;
	}
	&:last-of-type{
		&:after{
			display: none;
		}
	}
	&:after{
		content:'';
		background-image: linear-gradient(to right, $main-color-dark, $main-color-light);
		position: absolute;
		bottom: 0;
		left: 16rem;
		right: 8.2rem;
		height: 1px;
	}
}
.schedule-item__description{
	padding: 1.9rem 6rem 0 4.5rem;
	width: calc(100% - 34rem);
	float: left;
}
.schedule-item__title{
	margin-bottom: 0;
	& + .schedule-item__partner{
		margin-top: 1.4rem;
	}
}
@media screen and (max-width:$point-2) {
	.schedule-item__description{
		width: calc(100% - 20rem);
		padding-right: 0;
	}
	.schedule-item__frame:after{
		left: 2rem;
		right: 2rem;
	}
}
@media screen and (max-width:$point-3) {
	.s-schedule__frame{
		position: relative;
	}
	.schedule-item{
		flex-wrap: wrap;
	}
	.schedule-item-wrap{
		display: block;
	}
	.schedule-item__head{
		display: block;
		font-size: em(30);
		padding-right: 0;
	}
	.schedule-item__date{
		display: block;
		text-align: center;
	}
	.schedule-item__title{
		padding: 4.1rem 2rem 0 1.5rem;
		margin-bottom: 3.5rem;
	}
	.schedule-item__frame{
		display: block;
		padding: 0 1.5rem 3rem;
		& + .schedule-item__frame{
			padding-top: 3.6rem;
		}
		&:after{
			right: 1.5rem;
			left: 1.5rem;
		}
	}
	.schedule-item__description{
		font-size: em(16);
		line-height: em(24,16);
		width: 100%;
		padding: 4rem 0 0;
	}
}

.plan-wrap {
	padding-top: 8.4rem;

	img {
		display: block;
		max-width: 100%;
	}
}