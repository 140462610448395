.social-networks{
	@include flex_block(row, wrap, flex-start, flex-start, flex-start);
	@include reset-ul;
	position: relative;
	& + .scroll-down{
		padding-top: 4rem;
	}
	&._center{

	}
}
.social-networks__link{
	width: 3.8rem;
	height: 3.8rem;
	display: block;
	text-align: center;
	overflow: hidden;
	border: .2rem solid #fff;
	position: relative;
	color: #fff;
	.link-holder{
		height: 100%;
		@include flex_block(row, nowrap, center, center, center);
	}
	&:after{
		@include gradient-opacity;
		background-image: linear-gradient(to left, $main-color-light, $main-color-dark);
	}
	&:hover{
		color: $color-white !important;
		border-width: 0;
		border-color: #fff;
		&:after{
			@include gradient-opacity-hover;
		}
	}
	&._style-grey{
		border-width: .1rem;
		color: rgba(#fff, 0.7);
		&:hover{
			color: $color-white;
			border-width: 0 .1rem;
		}
	}
	&._style-black{
		border-color: $color-text-base;
		color: $color-text-base;
		&:hover{
			color: #fff;
		}
	}
}
.social-networks__item{
	@include transition;
	margin-left: 1rem;
	width: 3.8rem;
	&:first-child{
		margin-left: 0;
	}
	&.ml-0{
		margin-right: -.1rem;
		margin-top: -.1rem;
	}
	&:hover{

	}
	&:first-child{
		.social-networks__link{
			&._style-grey{
				&:hover{
					border-left-color: $main-color-dark;
				}
			}
		}
	}
	&:last-child{
		.social-networks__link{
			&._style-grey{
				&:hover{
					border-right-color: $main-color-light;
				}
			}
		}
	}
}
.social-networks__icon{
	display: inline-block;
	vertical-align: middle;
}