.icon {
	display: inline-block;
	vertical-align: top;
	width: 1em;
	height: 1em;
	fill: currentColor;
}

.icon-arrow-left {
	width:em(42);
	height:em(43);
}
.icon-arrow-right {
	width:em(42);
	height:em(43);
}
.icon-download {
	width:em(36);
	height:em(58);
}
.icon-facebook {
	width:em(9);
	height:em(18);
}
.icon-home {
	width:em(17);
	height:em(14);
}
.icon-instagram {
	width:em(17);
	height:em(17);
}
.icon-logo {
	width:em(390.16);
	height:em(235.52);
}
.icon-logo-4system {
	width:em(56);
	height:em(60);
}
.icon-logo-el {
	width:em(73.59);
	height:em(38.54);
}
.icon-logo-feather {
	width:em(169.33);
	height:em(44.73);
}
.icon-logo-kireev {
	width:em(51);
	height:em(15);
}
.icon-logo-lock-stock-barrel {
	width:em(60);
	height:em(60);
}
.icon-logo-reuzel {
	width:em(55);
	height:em(59.8);
}
.icon-logo-simushka {
	width:em(140);
	height:em(15);
}
.icon-moscow-city {
	width:em(390);
	height:em(96);
}
.icon-mouse-next {
	width:em(18);
	height:em(49);
}
.icon-print-house-logo {
	width:em(100);
	height:em(89);
}
.icon-rbw-logo {
	width:em(390.16);
	height:em(235.52);
}
.icon-triangle-rounded {
	width:em(38.3);
	height:em(60.2);
}
.icon-twitter {
	width:em(18);
	height:em(15);
}
.icon-vk {
	width:em(21);
	height:em(12);
}
.icon-youtube {
	width:em(17);
	height:em(12);
}
