.s-barbers{
	background-color: #1a0603;
}
.barber{
	@include flex_block(row, nowrap, flex-start, center, flex-start);
	text-align: left;
	margin-bottom: 3rem;
	&:last-child{
		margin-bottom: 0;
	}
	&._xs{
		@include flex_block(row, nowrap, flex-start, flex-start, flex-start);
		width: 34rem;
		margin-bottom: 0;
		float: left;
		.barber-avatar{
			min-width: 10.8rem;
			max-width: 10.8rem;
			height: 10.8rem;
		}
		.barber__name{
			font-size: em(20);
		}
		.barber__info{
			padding-top: 1.7rem;
		}
		.barber__job-place{
			margin-bottom: 2rem;
		}
	}
}
.barber-avatar{
	@include border-radius(50%);
	overflow: hidden;
	min-width: 15.2rem;
	max-width: 15.2rem;
	border: .4rem solid $main-color-light;
	margin-left: -.4rem;
}
.barber-square__avatar{
	min-width: 21rem;
	border: .5rem solid $main-color-light;
	margin-bottom: 1.1rem;

	img{
		display: block;
		width: 100%;
		-webkit-filter: grayscale(100%);
		filter: grayscale(100%);
	}
}
.barber__info{
	padding-left: 3.7rem;
}
.barber__name{
	font-size: em(24);
	line-height: em(26,24);
	font-weight: 900;
	margin-bottom: .9rem;
}
.barber-square__name{
	font-weight: 600;
	margin-bottom: .8rem;
}
.barber__job-place{
	margin-bottom: 1.3rem;
	line-height: em(20,20);
}
.barber-square__job-place{
	opacity: 0.6;
	font-size: em(14);
	margin-bottom: 1rem;
}
.barbers-group-wrap{
	width: 34rem;
	padding-top: .9rem;
	float: left;
	.barbers-group{
		padding-bottom: 4.7rem;
	}
}
.barbers-group{
	@include flex_block(row, nowrap, flex-start, center, flex-start);
	width: 100%;
	.barber-avatar{
		min-width: 10.2rem;
		max-width: 10.2rem;
		height: 10.2rem;
		margin-right: -2.6rem;
		&:first-child{
			z-index: 4;
		}
		&:nth-child(2) {
			z-index: 3;
		}
		&:nth-child(3) {
			z-index: 2;
		}
		&:last-child {
			z-index: 1;
		}
	}
}

.barber-columns{
	@include flex_block(row, wrap, space-between, flex-start, flex-start);
	margin-bottom: -6rem;
}
.barber-square{
	flex: 0 0 21rem;
	margin: 0 5rem 6rem 0;
	&:nth-child(3n){
		margin-right: 0;
	}
}
@media screen and (max-width:$point-2) {
	.barbers-group {
		.barber-avatar{
			margin-right: -4rem;
		}
	}
	.barber{
		flex-wrap: wrap;
		&._xs{
			flex-wrap: wrap;
			width: 20rem;
		}
	}
	.barber-avatar{
		min-width: 13.4rem;
		max-width: 13.4rem;
	}
	.barber__info{
		width: 100%;
		padding: 1.5rem 0 0;
	}
	.barber__name{
		font-size: em(20,20);
		line-height: em(26,20);
	}
	.barber__job-place{
		font-size: em(14,20);
	}
	.barbers-group-wrap{
		width: 20rem;
		min-width: 1px;
		padding-top: 0;
		.barbers-group {
			flex-wrap: wrap;
			.barber-avatar{
				margin: 0 -1.9rem 1.1rem 0;
				&:nth-child(2){
					margin-right: 0;
				}
			}
		}
	}
}
@media screen and (max-width:$point-3) {
	.barber{
		margin-bottom: 4rem;
		&._xs{
			.barber__job-place{
				font-size: em(20);
				margin-bottom: 2.6rem;
			}
		}
	}
	.barber__info{
		padding-top: 1.3rem;
	}
	.barber__job-place{
		margin-bottom: 1.6rem;
	}
	.barbers-group {
		.barber-avatar{
			margin-right: -2.5rem;
		}
	}
	.barbers-group-wrap{
		width: 100%;
		.barbers-group {
			padding-bottom: 2rem;
			.barber-avatar{
				margin: 0 1.1rem 1.1rem 0;
				&:nth-child(2){
					margin-right: 0;
				}
			}
		}
	}
	.barber-columns{
		justify-content: flex-start;
		margin: 0 -1rem -3rem;
	}
	.barber-square{
		margin: 0 1rem 3rem;
		width: calc(50% - 2rem);
		flex: auto;
		&:last-child{
			margin: 0 1rem 3rem;
		}
		&:nth-child(3n){
			margin-right: 1rem;
		}
	}
	.barber-square__avatar{
		width: 13rem;
		min-width: 13rem;
	}
	.barber-square__info{
		width: 13rem;
	}
}
@media screen and (max-width:$point-4) {
	.barbers-group {
		.barber-avatar{
			margin-right: -4.5rem;
		}
	}
}