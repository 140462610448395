.modal{
	&.navbar-modal{
		display: flex !important;
	}
}
.prevent-scroll {
	position: fixed !important;
	width: 100%;
}
@media screen and (max-width:$point-2) {
	.navbar-modal{
		@include transition(all, 0.4s, linear);
		pointer-events: none;
		-webkit-transition-delay: 600ms,600ms;
		transition-delay: 600ms,600ms;
		display: none;
		overflow: hidden;
		position: fixed;
		width: .1rem;
		height: .1rem;
		top: 7.9rem;
		right: 2rem;
		left: 2rem;
		z-index: 16;
		-webkit-overflow-scrolling: touch;
		outline: 0;
		background-color: transparent;
		&.in{
			width: calc(100% - 4rem);
			height: calc(100vh - 12.6rem);
			-webkit-transition-delay: 1ms, 1ms;
			transition-delay: 1ms, 1ms;
			pointer-events: auto;
			padding-right: 0 !important;
			background-color: $link-color;
			.navbar-modal__modal-content{
				@include transition(all, 0.4s, ease-in);
				.header__nav-item{
					opacity: 1;
					&:first-child{
						-webkit-transition-delay: 350ms,350ms;
						transition-delay: 350ms,350ms;
					}
					&:nth-child(2) {
						-webkit-transition-delay: 400ms,400ms;
						transition-delay: 400ms,400ms;
					}
					&:nth-child(3) {
						-webkit-transition-delay: 450ms,450ms;
						transition-delay: 450ms,450ms;
					}
					&:nth-child(4) {
						-webkit-transition-delay: 500ms,500ms;
						transition-delay: 500ms,500ms;
					}
				}
				.navbar-modal__social-wrap{
					opacity:1;
					-webkit-transition-delay: 550ms,550ms;
					transition-delay: 550ms,550ms;
				}
			}
		}
	}
	.navbar-modal__modal-content{
		padding: 2.8rem 1.3rem 6rem;
		width: 100%;
	}
	.modal-open {
		overflow: hidden;
		.modal {
			overflow-x: hidden;
			overflow-y: auto;
		}
	}
	.modal-scrollbar-measure {
		position: absolute;
		top: -9999px;
		width: 50px;
		height: 50px;
		overflow: scroll;
	}
}