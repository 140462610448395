.menu-opener{
	@include transition(all, .4s, ease-out)
	width: calc(100% - 17.8rem);
	max-width: 50%;
	height: 6.4rem;
	position: relative;
	z-index: 17;
	padding: 0 1rem;
	border: 0;
	background-image: linear-gradient(to left, $main-color-light, $main-color-dark);
	font: 800 1em $main-font;
	color: #fff;
	&:after{
		@include gradient-opacity;
		z-index: -1;
		background: #fff;
		opacity:0;
		-webkit-transition-delay: 700ms, 700ms;
		transition-delay: 700ms, 700ms;
	}
	&:hover,
	&:focus{
		border: 0;
	}
}
.menu-opener__inner{
	@include transition(all, 0.4s, ease-out);
	-webkit-transition-delay: 700ms, 700ms;
	transition-delay: 700ms, 700ms;
	pointer-events: none;
	position: relative;
	&:before,
	&:after{
		@include transition(all, 0.4s, ease-out);
		opacity:0;
		visibility: hidden;
		-webkit-transition-delay: 700ms, 700ms;
		transition-delay: 700ms, 700ms;
		position: absolute;
		width: 3.3rem;
		height: .8rem;
		background-color: $link-color;
		content:'';
		left: calc(50% - 1.5rem);
		top: calc(50% - .4rem);
	}
	&:before{
		transform: rotate(45deg);
	}
	&:after{
		transform: rotate(-45deg);
	}
}

.menu-opener{
	&.menu-active{
		background: #fff;

		&:after{
			@include gradient-opacity-hover;
			@include transition(all, 0.4s, ease-in);
			-webkit-transition-delay: 1ms, 1ms;
			transition-delay: 1ms, 1ms;
			opacity:1;
		}
		.menu-opener__inner,
		.menu-opener__inner:after,
		.menu-opener__inner:before{
			@include transition(all, 0.4s, ease-in);
			-webkit-transition-delay: 1ms, 1ms;
			transition-delay: 1ms, 1ms;
		}
		.menu-opener__inner{
			@include transform(rotate(180deg));
			font-size: 0;
			line-height: 0;
			&:before,
			&:after{
				background-color: #000;
				opacity:1;
				visibility: visible;
			}
		}
	}
}